import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { Device } from '@ionic-native/device/ngx';

import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic/ngx"

import { InstallationFCMApi } from '../shared/sdk/services/custom/InstallationFCM';
import { NotificationFCMApi } from '../shared/sdk/services/custom/NotificationFCM';
import { InstallationFCM, LoopBackFilter } from '../shared/sdk/models';
import { UserService } from './user/user.service';
import { DataService } from './data.service';


@Injectable({
  providedIn: 'root'
})
export class FCMService {

  constructor(
    private localNotifications: LocalNotifications,
    private device: Device,
    private router: Router,
    private fcm: FCM,

    private notificationFCMApi: NotificationFCMApi,
    private installationApi: InstallationFCMApi,

    private dataService: DataService,
    private userService: UserService,
    ) { }
    
  handleFCMToken() {
    console.log("handleFCMToken")
    this.fcm.hasPermission().then(hasPermission => {
        console.log("Has permission!", hasPermission);
    })
    this.fcm.getToken().then(token => {
      var userId = this.userService.getUserProfile().Id;
      this.getInstallation(token, userId).subscribe(
        dataIns => {
          if (!dataIns || dataIns.length <= 0) {
            fetch('assets/config.json').then(res => res.json()).then(
              json => {
                console.log('json...', json)
                var appId = json.fcmsdk_app_id;
                var installation: InstallationFCM = new InstallationFCM();
                installation.status = 'Active';
                installation.deviceType = this.device.platform.toLowerCase();
                installation.appId = appId
                installation.userId = userId
                installation.deviceToken = token
                installation.created = new Date();
                installation.modified = new Date();
                this.installationApi.patchOrCreate(installation).subscribe(
                  data => console.log("installation saved successfully", data),
                  error => console.log("error while creating installatin ", error)
                );
              }
            )

          }
        }, errorIns => { console.log("error while getting installation", errorIns) }
      )
    }, error => console.log("Error while getting token")
    );

  }

  sendNotification(title: string, message: string, toId: string, data) {
    console.log("sending info...", title, message, toId, data)
    this.notificationFCMApi.sendMessage(title, message, toId, data).subscribe(
      data => {
        console.log("Notification send successfully");
      },
      error => console.log("Error while sending notifications..", error)
    )
  }

  handleNotification() {
    this.handleNotificationClick();
    this.fcm.onNotification().subscribe(
      data => {
        console.log("Notification...", data)
        this.localNotifications.schedule(
          {
            id: 1,
            title: data.title,
            text: data.message,
            data: JSON.parse(data.data),
            sound: 'file://sound.mp3',
            // foreground: true,
          })
      },
      error => { console.log("Error on notification", error) })
  }

  handleNotificationClick() {
    console.log("handleNotificationClick")
    this.localNotifications.on('click').subscribe(
      res => {
        var data = res.data
        console.log("data on notification clicked...", data);
        if (data.EventName == "PAYMENT_TO_CONSULTANT") {
          this.router.navigate(['/sidebar/tabs/tabs/my-payments']);
        } else {
          this.router.navigate(['/sidebar/discussion'], { queryParams: { queryId: data.QueryId } });
        }
      },
      error => console.log("Error while clicking local notifications..", error)
    );
  }
  getInstallation(token, userId) {
    var filter: LoopBackFilter = {}
    filter.where = {
      "and": [
        { "userId": userId },
        { "deviceToken": token }
      ]
    }
    return this.installationApi.find(filter);
  }
  clearInstallation(userId: string) {
    if (this.dataService.$platform=='mobile') {
      this.fcm.getToken().then(token => {
        this.getInstallation(token, userId).subscribe(
          data => {
            if (data && data.length > 0) {
              var installations = <Array<InstallationFCM>>data;
              for (let installation of installations) {
                this.installationApi.deleteById(installation.id).subscribe(
                  data1 => console.log("delete output", data1),
                  error => console.log("error while deleteing...", error)
                )
              }
            }
          }, error => {
            console.log("error while finding installation", error)
          }
        )
      }, error => {
        console.log("error while getting token", error);
      });
    }

  }

}
