/* tslint:disable */

declare var Object: any;
export interface FnqQueryChargesInterface {
  "Id"?: string;
  "TypeDescription": string;
  "QueryType": string;
  "ChargesType": string;
  "ConsultantPercent": number;
  "Amount": number;
  "CreatedOn"?: Date;
  "CreatedById": string;
  "CreatedByName"?: string;
  "ModifiedOn"?: Date;
  "Modifiedby": string;
}

export class FnqQueryCharges implements FnqQueryChargesInterface {
  "Id": string;
  "TypeDescription": string;
  "QueryType": string;
  "ChargesType": string;
  "ConsultantPercent": number;
  "Amount": number;
  "CreatedOn": Date;
  "CreatedById": string;
  "CreatedByName": string;
  "ModifiedOn": Date;
  "Modifiedby": string;
  constructor(data?: FnqQueryChargesInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `FnqQueryCharges`.
   */
  public static getModelName() {
    return "FnqQueryCharges";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of FnqQueryCharges for dynamic purposes.
  **/
  public static factory(data: FnqQueryChargesInterface): FnqQueryCharges{
    return new FnqQueryCharges(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'FnqQueryCharges',
      plural: 'FnqQueryCharges',
      path: 'FnqQueryCharges',
      idName: 'Id',
      properties: {
        "Id": {
          name: 'Id',
          type: 'string'
        },
        "TypeDescription": {
          name: 'TypeDescription',
          type: 'string'
        },
        "QueryType": {
          name: 'QueryType',
          type: 'string'
        },
        "ChargesType": {
          name: 'ChargesType',
          type: 'string'
        },
        "ConsultantPercent": {
          name: 'ConsultantPercent',
          type: 'number'
        },
        "Amount": {
          name: 'Amount',
          type: 'number'
        },
        "CreatedOn": {
          name: 'CreatedOn',
          type: 'Date'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedByName": {
          name: 'CreatedByName',
          type: 'string'
        },
        "ModifiedOn": {
          name: 'ModifiedOn',
          type: 'Date'
        },
        "Modifiedby": {
          name: 'Modifiedby',
          type: 'string'
        },
      },
      relations: {
      }
    }
  }
}
