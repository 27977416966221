/* tslint:disable */
import {
  FnqConsultant
} from '../index';

declare var Object: any;
export interface FnqConsultantPaymentInterface {
  "Id"?: string;
  "ConsultantId"?: string;
  "FromDate"?: Date;
  "ToDate"?: Date;
  "PaymentStatus"?: boolean;
  "BankReferenceId"?: string;
  "Amount"?: number;
  "PaidOn"?: Date;
  Consultant?: FnqConsultant;
}

export class FnqConsultantPayment implements FnqConsultantPaymentInterface {
  "Id": string;
  "ConsultantId": string;
  "FromDate": Date;
  "ToDate": Date;
  "PaymentStatus": boolean;
  "BankReferenceId": string;
  "Amount": number;
  "PaidOn": Date;
  Consultant: FnqConsultant;
  constructor(data?: FnqConsultantPaymentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `FnqConsultantPayment`.
   */
  public static getModelName() {
    return "FnqConsultantPayment";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of FnqConsultantPayment for dynamic purposes.
  **/
  public static factory(data: FnqConsultantPaymentInterface): FnqConsultantPayment{
    return new FnqConsultantPayment(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'FnqConsultantPayment',
      plural: 'FnqConsultantPayments',
      path: 'FnqConsultantPayments',
      idName: 'Id',
      properties: {
        "Id": {
          name: 'Id',
          type: 'string'
        },
        "ConsultantId": {
          name: 'ConsultantId',
          type: 'string'
        },
        "FromDate": {
          name: 'FromDate',
          type: 'Date'
        },
        "ToDate": {
          name: 'ToDate',
          type: 'Date'
        },
        "PaymentStatus": {
          name: 'PaymentStatus',
          type: 'boolean'
        },
        "BankReferenceId": {
          name: 'BankReferenceId',
          type: 'string'
        },
        "Amount": {
          name: 'Amount',
          type: 'number'
        },
        "PaidOn": {
          name: 'PaidOn',
          type: 'Date'
        },
      },
      relations: {
        Consultant: {
          name: 'Consultant',
          type: 'FnqConsultant',
          model: 'FnqConsultant',
          relationType: 'belongsTo',
                  keyFrom: 'ConsultantId',
          keyTo: 'Id'
        },
      }
    }
  }
}
