import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  // {
  //   path: 'home',
  //   loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  // },
  {
    path: 'sidebar',
    loadChildren: () => import('./pages/menus/sidebar/sidebar.module').then( m => m.SidebarPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/my-profile/my-profile.module').then(m => m.MyProfilePageModule)
  },
  {
    path: 'pending-payments-detail-page',
    loadChildren: () => import('./pages/pending-payments-detail-page/pending-payments-detail-page.module').then( m => m.PendingPaymentsDetailPagePageModule)
  },
  {
    path: 'reset-password/:token',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'network-error',
    loadChildren: () => import('./pages/no-network/no-network.module').then(m => m.NoNetworkPageModule)
  },
  {
    path: 'email-verify',
    loadChildren: () => import('./pages/email-verify/email-verify.module').then( m => m.EmailVerifyPageModule)
  },
  {
    path: 'digio-handler',
    loadChildren: () => import('./pages/digio-handler/digio-handler.module').then( m => m.DigioHandlerPageModule)
  },  {
    path: 'landing',
    loadChildren: () => import('./pages/landing/landing.module').then( m => m.LandingPageModule)
  },

  

  // {
  //   path: 'kyc-details',
  //   loadChildren: () => import('./pages/kyc-details/kyc-details.module').then( m => m.KycDetailsPageModule)
  // },
  
  // {
  //   path: 'network-error',
  //   loadChildren: () => import('./pages/network-error/network-error.module').then( m => m.NetworkErrorPageModule)
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
