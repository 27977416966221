import { Injectable } from '@angular/core';

import { EmailSmsNotifyApi } from '../shared/sdk/services/custom/EmailSmsNotify';

@Injectable({
  providedIn: 'root'
})
export class NotifyUsersService {

  constructor(
    private EmailSmsNotifyApi: EmailSmsNotifyApi,
  ) { }

  async notifyUser(QueryId: string, ConsultantId: string, CustomerId: string, 
    EventName: 'QUERY_ASSIGNED' | 'RESPONSE_RECIEVED' | 'PAYMENT_TO_CONSULTANT' | 'ANSWERED_BY_CONSULTANT' 
    | 'QUERY_CLOSED' | 'CONSULTANT_REGISTERED' | 'CUSTOMER_PAYMENT_SUCCESS' | 'QUERY_SKIPPED' | 'LOE_SUBMITED' 
    | 'QUERY_SUBMITTED' | 'LOE_SIGNED', 
  Amount: number, ConsultantEmail: string, ConsultantPassword: string, TransactionId: string, TransactionDate: string) {
    try {
      console.log("notifyUser")
      var didNotify = await this.EmailSmsNotifyApi.notifyUsers(QueryId, ConsultantId, CustomerId, EventName, Amount, ConsultantEmail, ConsultantPassword, TransactionId, TransactionDate ).toPromise().then(data => { return data });
      return { success: true, didNotify: didNotify, message: "Notified the user." }
    } catch (error) {
      console.log(error);
      return { success: false, didNotify: null, message: error.message }
    }
  }

  async contactUs(Topic: string, Message: string, Phone:string, Name:string, Email: string) {
    try {
      var contactUs = await this.EmailSmsNotifyApi.contactUs(Topic, Message, Phone, Name, Email).toPromise().then(data => { return data });
      return { success: true, contactUs: contactUs, message: "Contacted us." }
    } catch (error) {
      console.log(error);
      return { success: false, contactUs: null, message: error.message }
    }
  }
}
