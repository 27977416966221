import { Component, NgZone } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AuthService } from './services/auth.service';
import { DataService } from './services/data.service';
import { UtilService } from './services/util.service';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { CategoriesService } from './services/categories/categories.service';
import { SubCategoriesService } from './services/categories/sub-categories.service';
import { MasterDataService } from './services/masterData/master-data.service';
import { FnqUserApi } from './shared/sdk';
import { UserService } from './services/user/user.service';
import { Router, NavigationStart, ActivatedRoute } from '@angular/router';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  initialUrl;
  initialParams;
  constructor(
    private userApi: FnqUserApi,

    private subCategoryService: SubCategoriesService,
    private masterDataService: MasterDataService,
    private categoryService: CategoriesService,
    private userService: UserService,
    private auth: AuthService,
    private util: UtilService,
    private data: DataService,
    private plt: Platform,
    private zone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    private deeplinks: Deeplinks,
    private screenOrientation: ScreenOrientation,

  ) {
    console.log("app component.......");
    let sub =  router.events.subscribe(event => {
      if(event instanceof NavigationStart) {
         console.log("event navigationStart ===>", event)
         if(!this.initialUrl){
           this.initialUrl = event.url
           this.initialParams = util.createParamsFromURL(document.URL);
      }
     }
    });

    this.plt.ready().then(async () => {

      console.log(this.plt.platforms())
      if (this.plt.is('desktop') || this.plt.is('mobileweb')|| this.plt.is('desktop')|| !document.URL.includes("localhost")) {
        this.data.$platform = 'web';
      } else if (this.plt.is('hybrid')) {
        this.data.$platform = 'mobile';
        this.initDeeplinks();
      }

      console.log(this.data.$platform);

      this.util.checkNetwork();

      this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);

      this.masterDataService.fetchAllMasterData();

      if(this.userApi.isAuthenticated()){

        this.auth.isAuthenticated.next(true);
        this.userService.setUserProfile(this.userService.getUserProfile());
        console.log("loggedin user..."+this.userService.getUserProfile())
        this.categoryService.fetchFormatCatSubCat();
      }
      this.zone.run(() => {
        console.log("intialUrl ==>", this.initialUrl)
        if(this.initialUrl && this.initialUrl.includes('/reset-password')){
          this.initialUrl = ""
        }else if(this.initialUrl && this.initialUrl.includes('digio-handler')){
          console.log("initialParams 2==>", this.initialParams)
          this.router.navigate(['/digio-handler'], { queryParams: this.initialParams });
        }else{
          this.util.routeToPage();
        }

        this.plt.backButton.subscribeWithPriority(5, () => {
          this.util.backButtonHandler();
        })
      })
    })
    if(this.data.$platform == 'mobile'){
      sub.unsubscribe();
    }

  }

  initDeeplinks() {
      this.deeplinks.route(
        { 'digio-handler': 'digio-handler' }
        ).subscribe(
            match => {
                console.log(match)
                this.zone.run(() => {
                this.router.navigate(['/digio-handler'], { queryParams: match.$args });
                });
            },
            nomatch => {
              console.log("Deeplink that didn't match", nomatch);
              }
          );
      }

}
