/* tslint:disable */
import {
  FnqConsultant,
  FnqCategory,
  FnqSubCategory
} from '../index';

declare var Object: any;
export interface FnqConsultantCategoriesInterface {
  "ConsultantId": string;
  "CategoryId": string;
  "SubCategoryId": string;
  "Id"?: string;
  consultant?: FnqConsultant;
  category?: FnqCategory;
  subCategory?: FnqSubCategory;
}

export class FnqConsultantCategories implements FnqConsultantCategoriesInterface {
  "ConsultantId": string;
  "CategoryId": string;
  "SubCategoryId": string;
  "Id": string;
  consultant: FnqConsultant;
  category: FnqCategory;
  subCategory: FnqSubCategory;
  constructor(data?: FnqConsultantCategoriesInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `FnqConsultantCategories`.
   */
  public static getModelName() {
    return "FnqConsultantCategories";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of FnqConsultantCategories for dynamic purposes.
  **/
  public static factory(data: FnqConsultantCategoriesInterface): FnqConsultantCategories{
    return new FnqConsultantCategories(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'FnqConsultantCategories',
      plural: 'FnqConsultantCategories',
      path: 'FnqConsultantCategories',
      idName: 'Id',
      properties: {
        "ConsultantId": {
          name: 'ConsultantId',
          type: 'string'
        },
        "CategoryId": {
          name: 'CategoryId',
          type: 'string'
        },
        "SubCategoryId": {
          name: 'SubCategoryId',
          type: 'string'
        },
        "Id": {
          name: 'Id',
          type: 'string'
        },
      },
      relations: {
        consultant: {
          name: 'consultant',
          type: 'FnqConsultant',
          model: 'FnqConsultant',
          relationType: 'belongsTo',
                  keyFrom: 'ConsultantId',
          keyTo: 'Id'
        },
        category: {
          name: 'category',
          type: 'FnqCategory',
          model: 'FnqCategory',
          relationType: 'belongsTo',
                  keyFrom: 'CategoryId',
          keyTo: 'Id'
        },
        subCategory: {
          name: 'subCategory',
          type: 'FnqSubCategory',
          model: 'FnqSubCategory',
          relationType: 'belongsTo',
                  keyFrom: 'SubCategoryId',
          keyTo: 'Id'
        },
      }
    }
  }
}
