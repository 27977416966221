import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { Network } from '@ionic-native/network/ngx';
import { Device } from '@ionic-native/device/ngx';
import { File } from '@ionic-native/file/ngx';
import { HTTP } from '@ionic-native/http/ngx';

import { FileUploader } from 'ng2-file-upload';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';

import { LoadingModalComponent } from '../components/loading-modal/loading-modal.component';
import { ContainerApi, FnqQueryInterface, FnqUserApi, LoopBackConfig } from '../shared/sdk';
import { MasterDataService } from './masterData/master-data.service';
import { PreviousRouteService } from './previous-route.service';
import { NotifyUsersService } from './notify-users.service';
import { Dropdown, FormFields } from './dtos.service';
import { QueryService } from './query/query.service';
import { UserService } from './user/user.service';
import { AuthService } from './auth.service';
import { DataService } from './data.service';
import { FCMService } from './fcm.service';
import { PaymentsService } from './payments/payments.service';
import { environment } from 'src/environments/environment';
import { KycDetailsComponent } from '../components/kyc-details/kyc-details.component';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  constructor(
    private toastController: ToastController,
    private modalController: ModalController,
    private alertController: AlertController,
    private ModalCtrl: ModalController,
    private paymentService: PaymentsService,
    private prevRoute: PreviousRouteService,
    private notifyUser:NotifyUsersService,
    private queryService: QueryService,
    private FcmService: FCMService,
    private auth: AuthService,
    private data: DataService,
    private user: UserService,

    private containerApi:ContainerApi,

    private fileOpener: FileOpener,
    private nativeHTTP: HTTP,
    private http: HttpClient,
    private network:Network,
    private device: Device,
    private router:Router,
    private file: File,
    private userApi: FnqUserApi
  ) { }

  public formFieldsToFormGroup(formBuilder:FormBuilder, fields: FormFields[][], group: FormGroup): FormGroup {
    let fg = {}
    fields.forEach(element => {
      element.forEach(field => {
        fg[field.controlName] = new FormControl({value: field.defaultValue, disabled: field.disabled? field.disabled:false}, field.validators)
      });
    });
    group = formBuilder.group(fg)
    return group
  }

  public async checkNetwork() {
    this.network.onConnect().subscribe((value) => {
      console.log("Network status changed", value);
      this.routeToPage();
    });
    this.network.onDisconnect().subscribe((value) => {
      console.log("Network status changed", value);
      this.router.navigateByUrl('/network-error')
    })
  }

  routeToPage(unSub:boolean = false) {
    console.log("routeToPage", this.data.$platform)
    if(this.userApi.isAuthenticated()){
      // if (status) {
        if (this.data.$platform == 'mobile') {
          this.FcmService.handleFCMToken();
          this.FcmService.handleNotification();
        }
        console.log( this.router.url)
        if (!this.router.url.includes('/reset-password')) {
          if (this.user.isAdmin()) {
            this.router.navigateByUrl('/sidebar/tabs/tabs/admin-dashboard')
          }else if(this.user.isCustomer() && this.data.$platform == 'mobile'){
            this.router.navigateByUrl('/landing')
          } 
          else {
            this.router.navigateByUrl('/sidebar/tabs/tabs/answer')
          }
        }
      } else {
        console.log( this.router)
        if (!this.router.url.includes('/reset-password')) {          
           if(this.data.$platform == 'mobile') {
            this.router.navigateByUrl('/landing')
          }else{
            this.router.navigateByUrl('/')
          }
        }
      }
      // if(unSub){
        // authSub.unsubscribe();
      // }
    // })
  }


  async downloadStoreAndOpen(fileName: string, url: string, mimetype) {
    console.log("In Dnld file and store");
    var filePath = "";
    if (this.device.platform == "Android") {
      filePath = this.file.externalDataDirectory + fileName
    } else {
      filePath = this.file.documentsDirectory + fileName
    }
    filePath = filePath.replaceAll(" ", "_");
    console.log(filePath);
    var download = await this.loadingModal();
    if(this.data.$platform == 'web'){
        var alert = await this.alertController.create({
          message: 'Do you want to download the file?',
          cssClass: 'logoutAlert',
          buttons: [
            { text: 'Ok',
            cssClass: 'custom-button',
            handler: () => {
              this.downloadWeb(url, download, mimetype)
              alert.dismiss(true);
              return false;
          }}, {
            text: 'Cancel',
            cssClass: 'custom-button',
            handler: () => {
              alert.dismiss(true);
              download.dismiss()
              return false;
          }}]
        });
        alert.present();

    }
    if (this.data.$platform == 'mobile') {

      this.nativeHTTP.downloadFile(url, {}, {}, filePath)
        .then( async (response) => {
          console.log('success block...', response, mimetype);
          download.dismiss();
          this.fileOpener.open(response.nativeURL, mimetype)
            .then( (result) => {
              console.log('success open dialog...', result);
            })
            .catch( (error) => {
              console.log('error open dialog...', error);
            });
        })
        .catch( async err => {
          download.dismiss();
          var errAlert = await this.alertController.create({
            header: 'Error',
            subHeader: 'Could not download file',
            message: err.error,
            cssClass: 'logoutAlert',
            buttons: [{ text: 'Okay', cssClass: 'custom-button'}]
          });
          errAlert.present();
          console.log('error block ... ', err.status);
          console.log('error block ... ', err.error);
          console.log('error block ... ', err);
        })
    }

  }

  backButtonHandler() {
    console.log("Back button Clicked")
    var backRoute = this.prevRoute.getPreviousUrl();
    console.log("Previous Route ==>", this.prevRoute.getPreviousUrl());
    if (backRoute == '/network-error') {
      this.router.navigateByUrl('/home')
    } else if (this.auth.isAuthenticated.value && backRoute == '/login') {
      navigator['app'].exitApp();
    } else {
      this.router.navigateByUrl(backRoute)
    }

  }

  downloadWeb(url, download, mimetype){
    this.http.get(url, {
      responseType: 'blob',
      reportProgress: true,
      observe: 'events'
    }).subscribe(async event => {
      if (event.type == HttpEventType.DownloadProgress) {
        console.log((100 * event.loaded) / event.total);
      } else if (event.type == HttpEventType.Response) {
          // Type is hard-coded else browser opens the file in the browser instead of downloading it
          download.dismiss();
          var type = mimetype
          // var type = mimetype == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'application/ms-excel';
          let blob = new Blob([event.body], { type: type });
          let url = window.URL.createObjectURL(blob);
          let pwa = window.open(url);
          if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
            var alert = await this.alertController.create({
              message: 'Please allow popups for this website',
              cssClass: 'logoutAlert',
              buttons: [{ text: 'Okay', cssClass: 'custom-button'}]
            });
            alert.present();
          }
      }
    })
  }

  formatUrl(fileId?: any, userId: string = this.user.getUserProfile().Id) {
    var url = "";
    if (fileId) {
      url = [LoopBackConfig.getPath(), LoopBackConfig.getApiVersion(), 'Containers', userId, 'download', fileId].join('/')
    } else {
      url = [LoopBackConfig.getPath(), LoopBackConfig.getApiVersion(), 'Containers', userId, 'upload'].join('/')
    }
    return url;
  }

  formatUrlForLoe(digiDocId) {
    var url = "";
    if (digiDocId) {
      url = [LoopBackConfig.getPath(), LoopBackConfig.getApiVersion(), 'Documents', 'loe', 'download', digiDocId].join('/')
    } else {
      url = [LoopBackConfig.getPath(), LoopBackConfig.getApiVersion(), 'Documents', 'loe', 'upload'].join('/')
    }
    return url;
  }

  areDatesEqual(date1: Date, date2: Date){
    var d1 = new Date(date1)
    var d2 = new Date(date2)
    return d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate();
  }

  orderId = "";

  public async loadingModal() {
    const modal = await this.modalController.create({
      component: LoadingModalComponent,
      cssClass: "loadingModal",
      backdropDismiss: false,
    })

    modal.present();
    return modal;
  }

  public initializeFileUploader(uploadURL) {
    var uploader = new FileUploader({
      url: uploadURL,
      disableMultipart: false,
      autoUpload: true,
      method: 'post',
      itemAlias: 'attachment',
      maxFileSize: 5 * 1024 * 1024,
      // allowedFileType: ['image', 'pdf'],
    });
    return uploader;
  }

  public async deleteFile(containerName: string, fileId: string) {
    try {
      var file = await this.containerApi.deleteFile(containerName, fileId).toPromise().then( data => { return data; });
      console.log(file);
      return { success: true, file: file, message:"File deleted successfully" }
    } catch (error) {
      console.log(error);
      return { success: false, file: null, message: error.message }
    }
  }

  async fetchDocsData(createdById: string, id: any) {
    var file = await this.queryService.getFile(createdById, id);
    if (file.success) {
      console.log(file.file);
      return file.file;
    } else {
      return {
        _id: "60eff29fe02f3414a0c6d0bd",
        aliases: null,
        chunkSize: 261120,
        contentType: "binary/octet-stream",
        filename: "File Not Found",
        length: 1007,
        md5: "38be050b08fa2596430378b1317bdc67",
        metadata: { container: "CN-00000009", filename: "File Not Found", mimetype: "image/png" },
        uploadDate: "2021-07-15T08:32:32.950Z",
      }
    }
  }

  public async paymentAlert(amount: number, queryData: FnqQueryInterface) {
    var header = "Payment of Rs. " + amount
    var message ='You may have to pay Rs.' + amount

    // this.paymentService.paymentSuccess.next(false);
     var alert = await this.alertController.create({
      header: header,
      message: message,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Skip',
          handler: () => {
            this.queryService.clearQueryVariables();
            this.router.navigateByUrl('/sidebar/tabs/tabs/answer')
          }
        },
        {
          text: 'Proceed',
          handler: async () => {
            // Handle Payment here
            var loading = await this.loadingModal();
            var query;
            if(this.queryService.queryId == null) {
              query = await (await this.queryService.saveQuery(queryData, amount)).query;
              console.log(" =====================> Save Query Called", query)
              this.queryService.queryId = query.Id;
            } else {
              query = await (await this.queryService.updateQuery(this.queryService.queryId, queryData, amount)).query;
              console.log(" =====================> Update Query Called", query)
              this.queryService.queryId = query.Id;
            }
            loading.dismiss();
            environment.production = true
            if (environment.production) {
              if(amount>0) {
                  this.paymentService.handlePayment(amount, this.user.getUserProfile(), query.CustomerPaymentDetails.RzpOrderId);
              } else {
                this.paymentService.paymentSuccess.next(true);
              }
            } else {
              this.paymentService.paymentSuccess.next(true);
            }
            var paySucessSub = this.paymentService.paymentSuccess.subscribe( async value => {
              console.log("value of payment response ===>", value )
              if (value && this.paymentService.paymentId) {
                var loading = await this.loadingModal();
                console.log(" =====================> xx Record Query Called")
                this.notifyUser.notifyUser(this.queryService.queryId, null, null, 'QUERY_ASSIGNED', null, null, null, null, null);
                this.notifyUser.notifyUser(this.queryService.queryId, null, null, 'QUERY_SUBMITTED', null, null, null, null, null);
                console.log(".......Notification sent .... ")
                this.queryService.clearQueryVariables();
                var recordQueryPayment = await this.queryService.recordQueryPayment(query, this.paymentService.paymentId, amount)
                if (recordQueryPayment.success) {
                  this.showToast("Query Submitted Successfully", "top");

                  this.router.navigateByUrl('/sidebar/tabs/tabs/answer').then(() => {
                    // this.queryService.clearQueryVariables()
                    this.notifyUser.notifyUser(query.Id, null, null, 'CUSTOMER_PAYMENT_SUCCESS', amount, null, null, this.paymentService.paymentId, moment().format('DD, MMM YYYY HH:MM'));

                  });
                } else {
                  console.log(recordQueryPayment)
                }
                loading.dismiss();
                paySucessSub.unsubscribe();
              }else{
                // this.router.navigateByUrl('')
              }
            });
          }
        }
      ]
    });
    alert.present()
    return alert;
  }

  public async loeAlert(amount: number, queryData: FnqQueryInterface){
    var query;
    var loading = await this.loadingModal();

    console.log("step1")
    if(this.queryService.queryId == null) {
      var query = await (await this.queryService.saveQuery(queryData, amount)).query;
      this.queryService.queryId = query.Id;
    } else {
      query = await (await this.queryService.updateQuery(this.queryService.queryId, queryData, amount)).query;
      this.queryService.queryId = query.Id;
    }
    console.log("step2", this.queryService.queryId)
    loading.dismiss()

    var alert = await this.alertController.create({
      header: "Letter Of Engagement",
      message: "Query is submmited Successfully. Please wait for consultant acceptance and Letter Of Engagement",
      backdropDismiss: false,
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.notifyUser.notifyUser(this.queryService.queryId, null, null, 'QUERY_ASSIGNED', null, null, null, null, null);
            this.notifyUser.notifyUser(this.queryService.queryId, null, null, 'QUERY_SUBMITTED', null, null, null, null, null);
            console.log(".......Notification sent .... ")
            this.queryService.clearQueryVariables();
            this.router.navigateByUrl('/sidebar/tabs/tabs/answer')
          }
        },

      ]
    });
    alert.present()
    return alert;

  }
  public async showToast(message: string, position:"bottom" | "top" | "middle" = 'bottom', durations: number = 3000) {
    var toast = await this.toastController.create({
      message: message,
      duration: durations,
      cssClass: 'toast-css',
      position: position,
    })
    toast.present()
    return toast;
  }

  public arrDelete(arr, elmnt?: any, indx?: number){
    arr.forEach((element, index) => {
      if(elmnt){
          if(element==elmnt){
          arr.splice(index, 1)
        }
      }
      if(indx){
        arr.splice(indx,1)
      }
    });
    return arr;
  }

  async makeHttpRequest(url: string, method: string, body?: any, headers?: HttpHeaders) {
    return await this.http.request(method, url, {
      body: body,
      headers: headers
    }).toPromise()
      .then(data => {
        return { success: true, data: data};
      })
      .catch(error => {
        return { success: false, data: error };
      });
  }

  formatCategories(categories:any[]):Dropdown[] {
    var formattedCategories:Dropdown[] = [];
    categories.forEach(element => {
      formattedCategories.push({ value: element.Id, text: element.Name })
    });
    return formattedCategories;
  }

  formatDate(date, format:string = 'DD-MM-YYYY'){
    return moment(date).format(format);
  }

  formatMasterDataToDropdown(MasterData):Dropdown[] {
    var formattedData:Dropdown[] = []
    for (var k in MasterData) {
      console.log(k);
      formattedData.push({ value: k, text: MasterData[k] });
    }
    return formattedData;
  }

  formatDegreesToDropdown(MasterData): Dropdown[] {
    var formattedData: Dropdown[] = []
    for (var k in MasterData) {
      if (k=="No") {
        formattedData.push({ value: k, text: MasterData[k] });
      } else {
        formattedData.push({ value: k, text: k + " - " + MasterData[k] });
      }
    }
    return formattedData;
  }

  async showKycDetails(kycRequestId, verify, queryId){
    console.log("showKycDetails ==>", kycRequestId, verify, queryId)
    const modal = await this.ModalCtrl.create({
      component: KycDetailsComponent,
      componentProps: {
        kycRequestId: kycRequestId,
        kycVerified: verify,
        queryId: queryId
      },
      cssClass: 'custom-modal-kyc',
    });
    await modal.present();

  }

  shuffle(array) {
    let currentIndex = array.length,  randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex != 0) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }
  base64ToArrayBuffer(data) {
    var binaryString = window.atob(data);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
  } ;

  createParamsFromURL(url){
    var initialParams = {}
    if (url.indexOf("?") > 0) {
      let splitURL = url.split("?");
      let splitParams = splitURL[1].split("&");
      for (let i in splitParams){
        let singleURLParam = splitParams[i].split('=');
        initialParams[singleURLParam[0]] = singleURLParam[1]
      }
    }
    return initialParams;
  }
}
