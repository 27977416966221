import { Component, OnInit, Input } from '@angular/core';
import { FnqKycApi, LoopBackFilter, FnqKyc, FnqQueryApi } from 'src/app/shared/sdk';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-kyc-details',
  templateUrl: './kyc-details.component.html',
  styleUrls: ['./kyc-details.component.scss'],
})
export class KycDetailsComponent implements OnInit {
  @Input() kycRequestId: string;
  @Input() kycVerified: boolean;
  @Input() queryId: string;

  
  constructor(
    private fnqKycApi: FnqKycApi,
    private sanitizer: DomSanitizer,
    private modalController: ModalController,
    private utilService: UtilService,
    private fnqQueryApi: FnqQueryApi) { }
  fnqKyc:FnqKyc
  profilePic 
  kyc
  async ngOnInit() {
    console.log("KYC status ==>", this.kycVerified)
    var filter: LoopBackFilter = {};
    var loading = await this.utilService.loadingModal();
    filter.where= {
      "KycRequestId": this.kycRequestId
    }
    this.fnqKycApi.find(filter).subscribe(
      data =>{
        console.log("KYCRequeest details", data)
        if(data && data.length > 0){
          this.fnqKyc = <FnqKyc> data[0];   
          this.kyc = JSON.parse(JSON.stringify(this.fnqKyc.KycDetails))
          //  = {}
          // this.kyc = temp.actions[0].details
          // console.log("image ==>", this.kyc.aadhar_image)
          if(this.kyc.aadhar_image != null){
            var imageData = btoa(this.kyc.aadhar_image);
            this.profilePic = this.sanitizer.bypassSecurityTrustResourceUrl("data:Image/*;base64,"+this.kyc.aadhar_image);
            console.log("profilePic ===>", this.profilePic)
          }   
         
        }
        loading.dismiss()
      },
      error => {
        console.log("error while getting KYC Requeest details", error)
        loading.dismiss()
      })
  }

  close(){
    console.log("close modal")
    this.modalController.dismiss({"kycVerified": this.kycVerified})
  }
  verifyKYC(){
    var reviewstatus = {"isKycReviewed": true}    
    this.fnqQueryApi.patchAttributes(this.queryId, {"isKycReviewed": true}).subscribe(
      data => {
        this.utilService.showToast("KYC Verified Successfully", "top", 5000);
        this.kycVerified = true;
        console.log("KYC Reviewed Successfully =>", data)
      }, error => {
        console.log("Error for verifyKYC  =>", error)
      }
    )
  }
}
