/* tslint:disable */
import {
  FnqCustomer
} from '../index';

declare var Object: any;
export interface FnqKycInterface {
  "Id"?: string;
  "KycRequestId"?: string;
  "KycStatus"?: string;
  "KycDetails"?: any;
  "KycResponse"?: any;
  "CustomerId": string;
  "CreatedOn"?: Date;
  "CreatedById": string;
  "CreatedByName"?: string;
  "ModifiedOn"?: Date;
  "ModifiedBy": string;
  Customer?: FnqCustomer;
}

export class FnqKyc implements FnqKycInterface {
  "Id": string;
  "KycRequestId": string;
  "KycStatus": string;
  "KycDetails": any;
  "KycResponse": any;
  "CustomerId": string;
  "CreatedOn": Date;
  "CreatedById": string;
  "CreatedByName": string;
  "ModifiedOn": Date;
  "ModifiedBy": string;
  Customer: FnqCustomer;
  constructor(data?: FnqKycInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `FnqKyc`.
   */
  public static getModelName() {
    return "FnqKyc";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of FnqKyc for dynamic purposes.
  **/
  public static factory(data: FnqKycInterface): FnqKyc{
    return new FnqKyc(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'FnqKyc',
      plural: 'FnqKycs',
      path: 'FnqKycs',
      idName: 'Id',
      properties: {
        "Id": {
          name: 'Id',
          type: 'string'
        },
        "KycRequestId": {
          name: 'KycRequestId',
          type: 'string'
        },
        "KycStatus": {
          name: 'KycStatus',
          type: 'string',
          default: 'false'
        },
        "KycDetails": {
          name: 'KycDetails',
          type: 'any',
          default: <any>null
        },
        "KycResponse": {
          name: 'KycResponse',
          type: 'any',
          default: <any>null
        },
        "CustomerId": {
          name: 'CustomerId',
          type: 'string'
        },
        "CreatedOn": {
          name: 'CreatedOn',
          type: 'Date'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedByName": {
          name: 'CreatedByName',
          type: 'string'
        },
        "ModifiedOn": {
          name: 'ModifiedOn',
          type: 'Date'
        },
        "ModifiedBy": {
          name: 'ModifiedBy',
          type: 'string'
        },
      },
      relations: {
        Customer: {
          name: 'Customer',
          type: 'FnqCustomer',
          model: 'FnqCustomer',
          relationType: 'belongsTo',
                  keyFrom: 'CustomerId',
          keyTo: 'Id'
        },
      }
    }
  }
}
