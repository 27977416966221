import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { FnqCategory, FnqCategoryApi, FnqCategoryInterface, FnqConsultantApi, FnqConsultantCategoriesApi, FnqConsultantCategoriesInterface, FnqConsultantInterface } from 'src/app/shared/sdk';

import { SubCategoriesService } from './sub-categories.service';
import { UserService } from '../user/user.service';
import { UtilService } from '../util.service';
import { Dropdown } from '../dtos.service';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  constructor(
    private consCat: FnqConsultantCategoriesApi,
    private consultantApi: FnqConsultantApi,
    private categories: FnqCategoryApi,

    private subCategoryService: SubCategoriesService,
    private user: UserService,
    private util: UtilService,
  ) { }

  private category: Dropdown[] = [];
  public categoriesFetched = new BehaviorSubject(false);

  clearVariables() {
    this.category = [];
    this.categoriesFetched.next(false);
  }

  async getConsultantsToForward(CatId: string, SubCatId: string) {
    var consultants = await this.consCat.find<FnqConsultantCategoriesInterface>({
      where: { and: [{ CategoryId: CatId, SubCategoryId: SubCatId }] }, include: {
        relation: 'consultant', scope: {
          include: {
            relation: 'User'
          }
        }
      }
    }).toPromise().then(data => { return data });
    return { success: true, data: consultants }
  }

  async getAllConsultantWithCatSubCat(catList: Dropdown[] = []) {
    var categories = []
    for (let cat of catList) {
      categories.push({CategoryId: cat.value});
    }
    var consultants = await this.consCat.find<FnqConsultantCategoriesInterface>({
      where:{ or: categories }, include: {
        relation: 'consultant', scope: {
          include: {
            relation: 'User'
          }
        }
      }
    }).toPromise().then(data => { return data });
    return { success: true, data: consultants }
  }


  async fetchFormatCatSubCat() {
    this.$category = await this.getUserCategories();
    for (const category of this.$category) {
      category.subValue = (await this.subCategoryService.getSubCategories(category.value)).formattedSubCat;
      console.log(category.subValue);
    }
    console.log("Categories ===============>", this.$category);
    this.categoriesFetched.next(true);
  }

  async getUserCategories() {
    var categories: FnqCategoryInterface[] = [];
    if (this.user.isCustomer()) {
      if (this.user.getUserProfile().OperatedAs == "INDIVIDUAL") {
        categories = await this.categories.find<FnqCategoryInterface>({ where: { CategoryType: 'INDIVIDUAL', Active: true } }).toPromise().then(data => { return data });
      } else if (this.user.getUserProfile().OperatedAs == "INDIVIDUAL_NRI") {
        categories = await this.categories.find<FnqCategoryInterface>({ where: { CategoryType: 'PREMIUM', Active: true } }).toPromise().then(data => { return data });
      } else {
        categories = await this.categories.find<FnqCategoryInterface>({ where: { CategoryType: 'BUSINESS', Active: true } }).toPromise().then(data => { return data });
      }
    } else if (this.user.isAdmin()) {
      categories = await this.categories.find<FnqCategoryInterface>({ where: { Active: true } }).toPromise().then(data => { return data });
    }
    return this.util.formatCategories(categories);
  }

  async getConsultantCategories(consultantId: string) {
    var retval: { success: boolean, conCategories: FnqConsultantCategoriesInterface[], message: string } = { success: false, conCategories: null, message: 'User Not Consultant' }
    try {
      var catCons = await this.consCat.find<FnqConsultantCategoriesInterface>({ where: { ConsultantId: consultantId }, include: ['consultant', 'category', { relation: 'subCategory', scope: { include: ['Category'] } }] }).toPromise().then(data => { return data });
      retval.success = true;
      retval.conCategories = catCons;
      return retval;
    } catch (error) {
      retval.message = error.message;
      return retval;
    }
  }

  async getCategory(CatId: string) {
    var category = await this.categories.find<FnqCategory>({ where: { _id: CatId } }).toPromise().then(data => { return data });
    return category
  }

  async getAllCategories() {
    var categories = await this.categories.find<FnqCategoryInterface>({ where: { Active: true } }).toPromise().then(data => { return data });
    return categories;
  }

  async createCategory(category) {
    try {
      var consCat = await this.categories.create(category).toPromise().then(data => { return data });
      return { success: true, consCat: consCat, message: "Query Added Successfully" }
    } catch (error) {
      return { success: false, consCat: null, message: error.message }
    }
  }

  async editCategory(CategoryId, data) {
    try {
      var edited = await this.categories.replaceById(CategoryId, data).toPromise().then(data => { return data })
      return { success: true, data: edited }
    } catch (err) {
      return { success: false, err: err }
    }
  }
  async createConsultantCategories(consultantCategory: FnqConsultantCategoriesInterface, numOfSkills: number) {
    try {
      var consultant = await this.consultantApi.upsertWithWhere<FnqConsultantInterface>({ Id: consultantCategory.ConsultantId }, { NumOfSkills: numOfSkills }).toPromise().then(data => { return data; });
      var consCat = await this.consCat.create<FnqConsultantCategoriesInterface>(consultantCategory).toPromise().then(data => { return data });
      return { success: true, consCat: consCat, message: "Consultant Category Added Successfully" }
    } catch (error) {
      return { success: false, consCat: null, message: error.message }
    }
  }
  async deleteConsultantCategories(consultantCategory: FnqConsultantCategoriesInterface, numOfSkills: number) {
    try {
      var consultant = await this.consultantApi.upsertWithWhere<FnqConsultantInterface>({ Id: consultantCategory.ConsultantId }, { NumOfSkills: numOfSkills }).toPromise().then(data => { return data; });
      var consCat = await this.consCat.deleteById<FnqConsultantCategoriesInterface>(consultantCategory.Id).toPromise().then(data => { return data });
      return { success: true, consCat: consCat, message: "Consultant Category Removed Successfully" }
    } catch (error) {
      return { success: false, consCat: null, message: error.message }
    }
  }

  async getAllConsultantCategories() {
    var conscat = await this.consCat.find({ include: 'subCategory' }).toPromise().then(data => { return data })
    return conscat
  }
  async DeletCategory(item) {
    item.Active = false;
    var data = await this.categories.upsertWithWhere({Id:item.Id}, item).toPromise().then(data => { return data })
    return { success: true, data: data, message: 'done' }
  }






  public get $category(): Dropdown[] {
    return this.category;
  }

  public set $category(value: Dropdown[]) {
    this.category = value;
  }

}
