/* tslint:disable */
import {
  FnqQuery,
  FnqConsultant,
  FnqCustomer,
  FnqConsultantPayment
} from '../index';

declare var Object: any;
export interface FnqQueryPaymentInterface {
  "Id"?: string;
  "QueryTitle"?: string;
  "QueryType"?: string;
  "ChargesType"?: string;
  "QueryId": string;
  "CustomerId": string;
  "ConsultantId"?: string;
  "CustomerAmount": number;
  "PaidOn"?: Date;
  "CustomerPaymentstatus": boolean;
  "RazorOrderId"?: string;
  "RazorPaymentId"?: string;
  "RazorSuccess"?: string;
  "RazorFailure"?: string;
  "ConsultantPaymentStatus"?: boolean;
  "ConsultantPaidOn"?: Date;
  "ConsultantAmount"?: number;
  "ConsultantPayout"?: number;
  "ConsulantGST"?: number;
  "ConsultantTDS"?: number;
  "PaymentGatewayCharge"?: number;
  "ConsultantPaymentHeaderId"?: string;
  Query?: FnqQuery;
  Consultant?: FnqConsultant;
  Customer?: FnqCustomer;
  ConsultantPaymentHeader?: FnqConsultantPayment;
}

export class FnqQueryPayment implements FnqQueryPaymentInterface {
  "Id": string;
  "QueryTitle": string;
  "QueryType": string;
  "ChargesType": string;
  "QueryId": string;
  "CustomerId": string;
  "ConsultantId": string;
  "CustomerAmount": number;
  "PaidOn": Date;
  "CustomerPaymentstatus": boolean;
  "RazorOrderId": string;
  "RazorPaymentId": string;
  "RazorSuccess": string;
  "RazorFailure": string;
  "ConsultantPaymentStatus": boolean;
  "ConsultantPaidOn": Date;
  "ConsultantAmount": number;
  "ConsultantPayout": number;
  "ConsulantGST": number;
  "ConsultantTDS": number;
  "PaymentGatewayCharge": number;
  "ConsultantPaymentHeaderId": string;
  Query: FnqQuery;
  Consultant: FnqConsultant;
  Customer: FnqCustomer;
  ConsultantPaymentHeader: FnqConsultantPayment;
  constructor(data?: FnqQueryPaymentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `FnqQueryPayment`.
   */
  public static getModelName() {
    return "FnqQueryPayment";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of FnqQueryPayment for dynamic purposes.
  **/
  public static factory(data: FnqQueryPaymentInterface): FnqQueryPayment{
    return new FnqQueryPayment(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'FnqQueryPayment',
      plural: 'FnqQueryPayments',
      path: 'FnqQueryPayments',
      idName: 'Id',
      properties: {
        "Id": {
          name: 'Id',
          type: 'string'
        },
        "QueryTitle": {
          name: 'QueryTitle',
          type: 'string'
        },
        "QueryType": {
          name: 'QueryType',
          type: 'string'
        },
        "ChargesType": {
          name: 'ChargesType',
          type: 'string'
        },
        "QueryId": {
          name: 'QueryId',
          type: 'string'
        },
        "CustomerId": {
          name: 'CustomerId',
          type: 'string'
        },
        "ConsultantId": {
          name: 'ConsultantId',
          type: 'string'
        },
        "CustomerAmount": {
          name: 'CustomerAmount',
          type: 'number'
        },
        "PaidOn": {
          name: 'PaidOn',
          type: 'Date'
        },
        "CustomerPaymentstatus": {
          name: 'CustomerPaymentstatus',
          type: 'boolean'
        },
        "RazorOrderId": {
          name: 'RazorOrderId',
          type: 'string'
        },
        "RazorPaymentId": {
          name: 'RazorPaymentId',
          type: 'string'
        },
        "RazorSuccess": {
          name: 'RazorSuccess',
          type: 'string'
        },
        "RazorFailure": {
          name: 'RazorFailure',
          type: 'string'
        },
        "ConsultantPaymentStatus": {
          name: 'ConsultantPaymentStatus',
          type: 'boolean'
        },
        "ConsultantPaidOn": {
          name: 'ConsultantPaidOn',
          type: 'Date'
        },
        "ConsultantAmount": {
          name: 'ConsultantAmount',
          type: 'number'
        },
        "ConsultantPayout": {
          name: 'ConsultantPayout',
          type: 'number'
        },
        "ConsulantGST": {
          name: 'ConsulantGST',
          type: 'number'
        },
        "ConsultantTDS": {
          name: 'ConsultantTDS',
          type: 'number'
        },
        "PaymentGatewayCharge": {
          name: 'PaymentGatewayCharge',
          type: 'number'
        },
        "ConsultantPaymentHeaderId": {
          name: 'ConsultantPaymentHeaderId',
          type: 'string'
        },
      },
      relations: {
        Query: {
          name: 'Query',
          type: 'FnqQuery',
          model: 'FnqQuery',
          relationType: 'belongsTo',
                  keyFrom: 'QueryId',
          keyTo: 'Id'
        },
        Consultant: {
          name: 'Consultant',
          type: 'FnqConsultant',
          model: 'FnqConsultant',
          relationType: 'belongsTo',
                  keyFrom: 'ConsultantId',
          keyTo: 'Id'
        },
        Customer: {
          name: 'Customer',
          type: 'FnqCustomer',
          model: 'FnqCustomer',
          relationType: 'belongsTo',
                  keyFrom: 'CustomerId',
          keyTo: 'Id'
        },
        ConsultantPaymentHeader: {
          name: 'ConsultantPaymentHeader',
          type: 'FnqConsultantPayment',
          model: 'FnqConsultantPayment',
          relationType: 'belongsTo',
                  keyFrom: 'ConsultantPaymentHeaderId',
          keyTo: 'Id'
        },
      }
    }
  }
}
