/* tslint:disable */

declare var Object: any;
export interface FnqCategoryInterface {
  "Id"?: string;
  "Name": string;
  "Active": boolean;
  "CategoryType": string;
}

export class FnqCategory implements FnqCategoryInterface {
  "Id": string;
  "Name": string;
  "Active": boolean;
  "CategoryType": string;
  constructor(data?: FnqCategoryInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `FnqCategory`.
   */
  public static getModelName() {
    return "FnqCategory";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of FnqCategory for dynamic purposes.
  **/
  public static factory(data: FnqCategoryInterface): FnqCategory{
    return new FnqCategory(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'FnqCategory',
      plural: 'FnqCategories',
      path: 'FnqCategories',
      idName: 'Id',
      properties: {
        "Id": {
          name: 'Id',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "Active": {
          name: 'Active',
          type: 'boolean',
          default: true
        },
        "CategoryType": {
          name: 'CategoryType',
          type: 'string'
        },
      },
      relations: {
      }
    }
  }
}
