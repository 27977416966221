/* tslint:disable */

declare var Object: any;
export interface FnqUserInterface {
  "Id"?: string;
  "FirstName": string;
  "LastName"?: string;
  "Dob": Date;
  "Gender": string;
  "Phone": number;
  "Country"?: string;
  "City"?: string;
  "ZipCode"?: string;
  "Occupation"?: string;
  "MeetingId"?: string;
  "PushId"?: string;
  "PushType"?: string;
  "Active": boolean;
  "CreatedOn"?: Date;
  "CreatedById": string;
  "CreatedByName"?: string;
  "ModifiedOn"?: Date;
  "ModifiedBy": string;
  "ProfilePic"?: string;
  "RoleName": string;
  "realm"?: string;
  "username"?: string;
  "email": string;
  "emailVerified"?: boolean;
  "password"?: string;
  accessTokens?: any[];
}

export class FnqUser implements FnqUserInterface {
  "Id": string;
  "FirstName": string;
  "LastName": string;
  "Dob": Date;
  "Gender": string;
  "Phone": number;
  "Country": string;
  "City": string;
  "ZipCode": string;
  "Occupation": string;
  "MeetingId": string;
  "PushId": string;
  "PushType": string;
  "Active": boolean;
  "CreatedOn": Date;
  "CreatedById": string;
  "CreatedByName": string;
  "ModifiedOn": Date;
  "ModifiedBy": string;
  "ProfilePic": string;
  "RoleName": string;
  "realm": string;
  "username": string;
  "email": string;
  "emailVerified": boolean;
  "password": string;
  accessTokens: any[];
  constructor(data?: FnqUserInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `FnqUser`.
   */
  public static getModelName() {
    return "FnqUser";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of FnqUser for dynamic purposes.
  **/
  public static factory(data: FnqUserInterface): FnqUser{
    return new FnqUser(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'FnqUser',
      plural: 'FnqUsers',
      path: 'FnqUsers',
      idName: 'Id',
      properties: {
        "Id": {
          name: 'Id',
          type: 'string'
        },
        "FirstName": {
          name: 'FirstName',
          type: 'string'
        },
        "LastName": {
          name: 'LastName',
          type: 'string'
        },
        "Dob": {
          name: 'Dob',
          type: 'Date'
        },
        "Gender": {
          name: 'Gender',
          type: 'string'
        },
        "Phone": {
          name: 'Phone',
          type: 'number'
        },
        "Country": {
          name: 'Country',
          type: 'string'
        },
        "City": {
          name: 'City',
          type: 'string'
        },
        "ZipCode": {
          name: 'ZipCode',
          type: 'string'
        },
        "Occupation": {
          name: 'Occupation',
          type: 'string'
        },
        "MeetingId": {
          name: 'MeetingId',
          type: 'string'
        },
        "PushId": {
          name: 'PushId',
          type: 'string'
        },
        "PushType": {
          name: 'PushType',
          type: 'string'
        },
        "Active": {
          name: 'Active',
          type: 'boolean',
          default: true
        },
        "CreatedOn": {
          name: 'CreatedOn',
          type: 'Date'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedByName": {
          name: 'CreatedByName',
          type: 'string'
        },
        "ModifiedOn": {
          name: 'ModifiedOn',
          type: 'Date'
        },
        "ModifiedBy": {
          name: 'ModifiedBy',
          type: 'string'
        },
        "ProfilePic": {
          name: 'ProfilePic',
          type: 'string'
        },
        "RoleName": {
          name: 'RoleName',
          type: 'string'
        },
        "realm": {
          name: 'realm',
          type: 'string'
        },
        "username": {
          name: 'username',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "emailVerified": {
          name: 'emailVerified',
          type: 'boolean'
        },
        "password": {
          name: 'password',
          type: 'string'
        },
      },
      relations: {
        accessTokens: {
          name: 'accessTokens',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'Id',
          keyTo: 'userId'
        },
      }
    }
  }
}
