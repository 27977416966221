/* tslint:disable */
import {
  FnqUser
} from '../index';

declare var Object: any;
export interface FnqConsultantInterface {
  "Id"?: string;
  "Degree": string;
  "College": string;
  "PracticingYear": number;
  "EmployeeStatus": string;
  "Eligible": boolean;
  "BankName"?: string;
  "BankBranch"?: string;
  "IfscCode"?: string;
  "BankAccountNumber"?: string;
  "RbiNumber"?: string;
  "GstNumber"?: string;
  "ProfessionalNumber"?: string;
  "StarRating"?: number;
  "RatingCount"?: number;
  "UserId"?: string;
  "Docs"?: Array<any>;
  "DisplayName"?: string;
  "NumOfSkills"?: number;
  User?: FnqUser;
}

export class FnqConsultant implements FnqConsultantInterface {
  "Id": string;
  "Degree": string;
  "College": string;
  "PracticingYear": number;
  "EmployeeStatus": string;
  "Eligible": boolean;
  "BankName": string;
  "BankBranch": string;
  "IfscCode": string;
  "BankAccountNumber": string;
  "RbiNumber": string;
  "GstNumber": string;
  "ProfessionalNumber": string;
  "StarRating": number;
  "RatingCount": number;
  "UserId": string;
  "Docs": Array<any>;
  "DisplayName": string;
  "NumOfSkills": number;
  User: FnqUser;
  constructor(data?: FnqConsultantInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `FnqConsultant`.
   */
  public static getModelName() {
    return "FnqConsultant";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of FnqConsultant for dynamic purposes.
  **/
  public static factory(data: FnqConsultantInterface): FnqConsultant{
    return new FnqConsultant(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'FnqConsultant',
      plural: 'FnqConsultants',
      path: 'FnqConsultants',
      idName: 'Id',
      properties: {
        "Id": {
          name: 'Id',
          type: 'string'
        },
        "Degree": {
          name: 'Degree',
          type: 'string'
        },
        "College": {
          name: 'College',
          type: 'string'
        },
        "PracticingYear": {
          name: 'PracticingYear',
          type: 'number'
        },
        "EmployeeStatus": {
          name: 'EmployeeStatus',
          type: 'string'
        },
        "Eligible": {
          name: 'Eligible',
          type: 'boolean',
          default: false
        },
        "BankName": {
          name: 'BankName',
          type: 'string'
        },
        "BankBranch": {
          name: 'BankBranch',
          type: 'string'
        },
        "IfscCode": {
          name: 'IfscCode',
          type: 'string'
        },
        "BankAccountNumber": {
          name: 'BankAccountNumber',
          type: 'string'
        },
        "RbiNumber": {
          name: 'RbiNumber',
          type: 'string'
        },
        "GstNumber": {
          name: 'GstNumber',
          type: 'string'
        },
        "ProfessionalNumber": {
          name: 'ProfessionalNumber',
          type: 'string'
        },
        "StarRating": {
          name: 'StarRating',
          type: 'number',
          default: 0
        },
        "RatingCount": {
          name: 'RatingCount',
          type: 'number',
          default: 0
        },
        "UserId": {
          name: 'UserId',
          type: 'string'
        },
        "Docs": {
          name: 'Docs',
          type: 'Array&lt;any&gt;'
        },
        "DisplayName": {
          name: 'DisplayName',
          type: 'string'
        },
        "NumOfSkills": {
          name: 'NumOfSkills',
          type: 'number'
        },
      },
      relations: {
        User: {
          name: 'User',
          type: 'FnqUser',
          model: 'FnqUser',
          relationType: 'belongsTo',
                  keyFrom: 'UserId',
          keyTo: 'Id'
        },
      }
    }
  }
}
