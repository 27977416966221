/* tslint:disable */
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SDKModels } from './SDKModels';
import { BaseLoopBackApi } from '../core/base.service';
import { LoopBackConfig } from '../../lb.config';
import { LoopBackAuth } from '../core/auth.service';
import { LoopBackFilter,  } from '../../models/BaseModels';
import { ErrorHandler } from '../core/error.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { EmailSmsNotify } from '../../models/EmailSmsNotify';
import { SocketConnection } from '../../sockets/socket.connections';


/**
 * Api services for the `EmailSmsNotify` model.
 */
@Injectable()
export class EmailSmsNotifyApi extends BaseLoopBackApi {

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SocketConnection) protected connection: SocketConnection,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http,  connection,  models, auth, errorHandler);
  }

  /**
   * Patch an existing model instance or insert a new one into the data source.
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - Model instance data
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `EmailSmsNotify` object.)
   * </em>
   */
  public patchOrCreate(data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/EmailSmsNotifies";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   * @param {any} id EmailSmsNotify id
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - An object of model property name/value pairs
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `EmailSmsNotify` object.)
   * </em>
   */
  public patchAttributes(id: any, data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/EmailSmsNotifies/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Notify users with Sms, Notification or Email.
   *
   * @param {object} data Request data.
   *
   *  - `QueryId` – `{string}` - 
   *
   *  - `ConsultantId` – `{string}` - 
   *
   *  - `CustomerId` – `{string}` - 
   *
   *  - `EventName` – `{string}` - 
   *
   *  - `Amount` – `{string}` - 
   *
   *  - `ConsultantEmail` – `{string}` - 
   *
   *  - `ConsultantPassword` – `{string}` - 
   *
   *  - `TransactionId` – `{string}` - 
   *
   *  - `TransactionDate` – `{string}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public notifyUsers(QueryId: any = {}, ConsultantId: any = {}, CustomerId: any = {}, EventName: any, Amount: any = {}, ConsultantEmail: any = {}, ConsultantPassword: any = {}, TransactionId: any = {}, TransactionDate: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/EmailSmsNotifies/notifyUsers";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof QueryId !== 'undefined' && QueryId !== null) _urlParams.QueryId = QueryId;
    if (typeof ConsultantId !== 'undefined' && ConsultantId !== null) _urlParams.ConsultantId = ConsultantId;
    if (typeof CustomerId !== 'undefined' && CustomerId !== null) _urlParams.CustomerId = CustomerId;
    if (typeof EventName !== 'undefined' && EventName !== null) _urlParams.EventName = EventName;
    if (typeof Amount !== 'undefined' && Amount !== null) _urlParams.Amount = Amount;
    if (typeof ConsultantEmail !== 'undefined' && ConsultantEmail !== null) _urlParams.ConsultantEmail = ConsultantEmail;
    if (typeof ConsultantPassword !== 'undefined' && ConsultantPassword !== null) _urlParams.ConsultantPassword = ConsultantPassword;
    if (typeof TransactionId !== 'undefined' && TransactionId !== null) _urlParams.TransactionId = TransactionId;
    if (typeof TransactionDate !== 'undefined' && TransactionDate !== null) _urlParams.TransactionDate = TransactionDate;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Contact Us
   *
   * @param {object} data Request data.
   *
   *  - `Topic` – `{string}` - 
   *
   *  - `Message` – `{string}` - 
   *
   *  - `Phone` – `{string}` - 
   *
   *  - `Name` – `{string}` - 
   *
   *  - `Email` – `{string}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `MailSent` – `{any}` - 
   */
  public contactUs(Topic: any, Message: any, Phone: any, Name: any, Email: any, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/EmailSmsNotifies/contactUs";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof Topic !== 'undefined' && Topic !== null) _urlParams.Topic = Topic;
    if (typeof Message !== 'undefined' && Message !== null) _urlParams.Message = Message;
    if (typeof Phone !== 'undefined' && Phone !== null) _urlParams.Phone = Phone;
    if (typeof Name !== 'undefined' && Name !== null) _urlParams.Name = Name;
    if (typeof Email !== 'undefined' && Email !== null) _urlParams.Email = Email;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `EmailSmsNotify`.
   */
  public getModelName() {
    return "EmailSmsNotify";
  }
}
