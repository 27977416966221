
import { NgModule } from '@angular/core';


import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { CustomPipe } from './pipes/custom.pipe';
import { AppComponent } from './app.component';

import { SDKBrowserModule } from './shared/sdk';

import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic/ngx";
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { Network } from '@ionic-native/network/ngx';
import { Device } from '@ionic-native/device/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { File } from '@ionic-native/file/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { YoutubeVideoPlayer } from '@ionic-native/youtube-video-player/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@NgModule({
  declarations: [AppComponent, CustomPipe],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, SDKBrowserModule.forRoot()],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, FileOpener, HTTP, File, Device,
     Network, ScreenOrientation, FCM, LocalNotifications, GoogleAnalytics, NativeStorage, Deeplinks, YoutubeVideoPlayer,
     InAppBrowser  ],
  bootstrap: [AppComponent],

})
export class AppModule {}
