import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { BehaviorSubject } from 'rxjs';

import { FnqConsultantApi, FnqConsultantInterface, FnqCustomerApi, FnqCustomerInterface, FnqUserApi, LoopBackAuth, FnqCustomer, FnqUser, LoopBackFilter } from 'src/app/shared/sdk';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  userProfile = new BehaviorSubject(null);
  customerProfile:FnqCustomer;
  userProfileDetails: FnqUser
  constructor(
    private lbAuth: LoopBackAuth,

    private consultantApi: FnqConsultantApi,
    private customerApi: FnqCustomerApi,
    private userApi: FnqUserApi
  ) { }

  getUserProfile() {
    return this.lbAuth.getCurrentUserData();
  }

  setUserProfile(user) {
    this.userProfile.next(user);
    this.lbAuth.setUser(user);
    
  }
  setUserProfileDetails(userId){
    this.getUserProfileById(userId).then(
      data => {
        this.userProfileDetails = <FnqUser>data
      }
    )
  }

  async getUserProfileById(userId: string) {
    var userProfile = null;
    var user: FnqCustomerInterface | FnqConsultantInterface;
    if (userId.startsWith('CS')) {
      user = await this.customerApi.findById<FnqCustomerInterface>(userId, { include: ['User'] }).toPromise().then(data => { return data; });
    }
    if (userId.startsWith('CN')) {
      user = await this.consultantApi.findById<FnqConsultantInterface>(userId, { include: ['User'] }).toPromise().then(data => { return data; });
    }
    var userDetails = user.User;
    userProfile = Object.assign(userDetails, user);
    return userProfile;
  }

  getCustomerProfile(customerId){
    this.customerApi.findById(customerId).subscribe(
        data => { 
          this.customerProfile = <FnqCustomer>data;
      });
    
  }

  async getCustomerProfileByUserId(userId){
    var filter: LoopBackFilter = {};
    filter.where = { UserId: userId }
    await this.customerApi.find(filter).subscribe(
        data => { 
          console.log("customerProfileData ==>", data)
          if(data && data.length > 0)
          this.customerProfile = <FnqCustomer>data[0];
          console.log("customerProfile ==>", this.customerProfile)
      });
    
  }
  isCustomer() {
    return this.userApi.isAuthenticated() ? this.lbAuth.getCurrentUserData().RoleName == 'CUSTOMER' : false;
  }

  isConsultant() {
    return this.userApi.isAuthenticated() ? this.lbAuth.getCurrentUserData().RoleName == 'CONSULTANT' : false;
  }

  isAdmin() {
    return this.userApi.isAuthenticated() ? this.lbAuth.getCurrentUserData().RoleName == 'ADMIN' : false;
  }

  getToken() {
    return this.lbAuth.getToken();
  }

  async updateRiskScore(userId: string, riskScore: number) {
    var user = await this.customerApi.upsertWithWhere<FnqCustomerInterface>({ UserId: userId }, { RiskScore: riskScore, RiskScoreTimestamp: new Date(new Date().setFullYear(new Date().getFullYear() + 1)) }).toPromise().then(data => { return data; });
    console.log(user);
  }

  async updateKYCStatus(kycStatus: string, kycCompleted: string, kycRequestId: string, userId: string = this.lbAuth.getCurrentUserData().UserId) {
    var user = await this.customerApi.upsertWithWhere<FnqCustomerInterface>(
      { UserId: userId }, 
      { KycRequestId: kycRequestId, KycComplete: kycCompleted,  KycStatus: kycStatus,
        KycValidity: new Date(new Date().setFullYear(new Date().getFullYear() + 1)) }
      ).toPromise().then(data => { return data; });
    console.log(user);
  }
}
