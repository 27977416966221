/* tslint:disable */
import { Injectable } from '@angular/core';
import { User } from '../../models/User';
import { FnqUser } from '../../models/FnqUser';
import { FnqSubCategory } from '../../models/FnqSubCategory';
import { FnqCategory } from '../../models/FnqCategory';
import { FnqConsultant } from '../../models/FnqConsultant';
import { FnqConsultantPayment } from '../../models/FnqConsultantPayment';
import { FnqCustomer } from '../../models/FnqCustomer';
import { FnqConsultantCategories } from '../../models/FnqConsultantCategories';
import { FnqQueryCharges } from '../../models/FnqQueryCharges';
import { FnqRiskQuestions } from '../../models/FnqRiskQuestions';
import { MasterData } from '../../models/MasterData';
import { FnqQuery } from '../../models/FnqQuery';
import { Sequence } from '../../models/Sequence';
import { Container } from '../../models/Container';
import { FnqQueryPayment } from '../../models/FnqQueryPayment';
import { Email } from '../../models/Email';
import { EmailSmsNotify } from '../../models/EmailSmsNotify';
import { Sms } from '../../models/Sms';
import { ApplicationFCM } from '../../models/ApplicationFCM';
import { NotificationFCM } from '../../models/NotificationFCM';
import { PushFCM } from '../../models/PushFCM';
import { InstallationFCM } from '../../models/InstallationFCM';
import { FnqRejections } from '../../models/FnqRejections';
import { FnqKyc } from '../../models/FnqKyc';
import { Document } from '../../models/Document';
import { FnqLoe } from '../../models/FnqLoe';

export interface Models { [name: string]: any }

@Injectable()
export class SDKModels {

  private models: Models = {
    User: User,
    FnqUser: FnqUser,
    FnqSubCategory: FnqSubCategory,
    FnqCategory: FnqCategory,
    FnqConsultant: FnqConsultant,
    FnqConsultantPayment: FnqConsultantPayment,
    FnqCustomer: FnqCustomer,
    FnqConsultantCategories: FnqConsultantCategories,
    FnqQueryCharges: FnqQueryCharges,
    FnqRiskQuestions: FnqRiskQuestions,
    MasterData: MasterData,
    FnqQuery: FnqQuery,
    Sequence: Sequence,
    Container: Container,
    FnqQueryPayment: FnqQueryPayment,
    Email: Email,
    EmailSmsNotify: EmailSmsNotify,
    Sms: Sms,
    ApplicationFCM: ApplicationFCM,
    NotificationFCM: NotificationFCM,
    PushFCM: PushFCM,
    InstallationFCM: InstallationFCM,
    FnqRejections: FnqRejections,
    FnqKyc: FnqKyc,
    Document: Document,
    FnqLoe: FnqLoe,
    
  };

  public get(modelName: string): any {
    return this.models[modelName];
  }

  public getAll(): Models {
    return this.models;
  }

  public getModelNames(): string[] {
    return Object.keys(this.models);
  }
}
