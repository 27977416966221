/* tslint:disable */

declare var Object: any;
export interface EmailSmsNotifyInterface {
  "EventName": string;
  "SendSms": boolean;
  "SmsHeader"?: string;
  "SmsBody"?: string;
  "SendEmail": boolean;
  "EmailHeader"?: string;
  "EmailBody"?: string;
  "SendNotification": boolean;
  "NotificationHeader"?: string;
  "NotificationBody"?: string;
  "To": string;
  "DLT_TE_ID"?: string;
  "id"?: any;
}

export class EmailSmsNotify implements EmailSmsNotifyInterface {
  "EventName": string;
  "SendSms": boolean;
  "SmsHeader": string;
  "SmsBody": string;
  "SendEmail": boolean;
  "EmailHeader": string;
  "EmailBody": string;
  "SendNotification": boolean;
  "NotificationHeader": string;
  "NotificationBody": string;
  "To": string;
  "DLT_TE_ID": string;
  "id": any;
  constructor(data?: EmailSmsNotifyInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `EmailSmsNotify`.
   */
  public static getModelName() {
    return "EmailSmsNotify";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of EmailSmsNotify for dynamic purposes.
  **/
  public static factory(data: EmailSmsNotifyInterface): EmailSmsNotify{
    return new EmailSmsNotify(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'EmailSmsNotify',
      plural: 'EmailSmsNotifies',
      path: 'EmailSmsNotifies',
      idName: 'id',
      properties: {
        "EventName": {
          name: 'EventName',
          type: 'string'
        },
        "SendSms": {
          name: 'SendSms',
          type: 'boolean',
          default: false
        },
        "SmsHeader": {
          name: 'SmsHeader',
          type: 'string'
        },
        "SmsBody": {
          name: 'SmsBody',
          type: 'string'
        },
        "SendEmail": {
          name: 'SendEmail',
          type: 'boolean',
          default: false
        },
        "EmailHeader": {
          name: 'EmailHeader',
          type: 'string'
        },
        "EmailBody": {
          name: 'EmailBody',
          type: 'string'
        },
        "SendNotification": {
          name: 'SendNotification',
          type: 'boolean',
          default: false
        },
        "NotificationHeader": {
          name: 'NotificationHeader',
          type: 'string'
        },
        "NotificationBody": {
          name: 'NotificationBody',
          type: 'string'
        },
        "To": {
          name: 'To',
          type: 'string'
        },
        "DLT_TE_ID": {
          name: 'DLT_TE_ID',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
      }
    }
  }
}
