import { Injectable } from '@angular/core';

import { FnqSubCategory, FnqSubCategoryApi, FnqSubCategoryInterface, LoopBackFilter } from 'src/app/shared/sdk';

import { UtilService } from '../util.service';
import { Dropdown } from '../dtos.service';

@Injectable({
  providedIn: 'root'
})
export class SubCategoriesService {

  constructor(
    private subCategories: FnqSubCategoryApi,
    private util: UtilService,
  ) { }

  private subCategory: Dropdown[] = [];

  async getSubCategories(categoryId: string) {
    var subcategory: FnqSubCategoryInterface[] = await this.subCategories.find<FnqSubCategoryInterface>({ where: { CategoryId: categoryId, Active: true }, include: ['Category'] }).toPromise().then(data => { return data });
    return { formattedSubCat: this.util.formatCategories(subcategory), data: subcategory };
  }

  async getSubCategoryById(subCatId: string) {
    var subcategory = await this.subCategories.findOne<FnqSubCategory>({ where: { _id: subCatId } }).toPromise().then(data => { return data });
    return subcategory;
  }

  async getAllSubCategories() {
    var filter: LoopBackFilter = { where: { Active: true } , include: ['Category']};
    var subCategories = await this.subCategories.find<FnqSubCategoryInterface>(filter).toPromise().then(data => { return data });
    return subCategories;
  }

  async createSubCategory(Data) {
    try{
      var SubCat=await this.subCategories.create(Data).toPromise().then(data => { return data });
      return {success:true,data:SubCat,message:'Created'}
    }catch(err){
      return {success:false,data:err,message:'Please Select all fields'}
    }
  }

  async deactivateSubCategory(subCat){
    subCat.Active = false;
    console.log(subCat)
    var data=await this.subCategories.upsertWithWhere({Id:subCat.Id}, subCat).toPromise().then(data=>{return data})
    return {success:true,data:data,message:'done'}
  }

  async updateSubCategory(SubCategoryId,data) {
    try{
      var edited=await this.subCategories.replaceById(SubCategoryId,data).toPromise().then(data=>{return data})
      return {success:true,data:edited}
      }catch(err){
        return {success:false,err:err}
    
      }

  }


  public get $subCategory(): Dropdown[] {
    return this.subCategory;
  }

  public set $subCategory(value: Dropdown[]) {
    this.subCategory = value;
  }

}
