/* tslint:disable */
import {
  FnqSubCategory,
  FnqCategory,
  FnqConsultant,
  FnqCustomer
} from '../index';

declare var Object: any;
export interface FnqQueryInterface {
  "Id"?: string;
  "Title": string;
  "CategoryId": string;
  "SubcategoryId": string;
  "QueryDesc": string;
  "QueryType": string;
  "QueryChargeType": string;
  "CustomerId": string;
  "ConsultantId"?: string;
  "Answered"?: boolean;
  "Closed"?: boolean;
  "ClosedOn"?: Date;
  "Iterations"?: number;
  "CreatedOn"?: Date;
  "CreatedById": string;
  "CreatedByName"?: string;
  "ModifiedOn"?: Date;
  "ModifiedBy": string;
  "ExpectedResolution"?: string;
  "CustomerTurn"?: boolean;
  "ConsultantTurn"?: boolean;
  "IsActive"?: boolean;
  "Forwarded"?: boolean;
  "IsAmtRequested"?: boolean;
  "Rating"?: number;
  "PreferedQualification"?: string;
  "MeetingUrl"?: string;
  "MeetingType"?: string;
  "MeetingDuration"?: number;
  "MeetingTime"?: string;
  "IsMeeting"?: boolean;
  "Hour24"?: boolean;
  "Hour48"?: boolean;
  "Meetings"?: Array<any>;
  "Docs"?: Array<any>;
  "Answers"?: Array<any>;
  "FinancialHealthCheck"?: any;
  "CustomerPaymentStatus"?: boolean;
  "CustomerPaymentDetails"?: any;
  "CustomerName"?: string;
  "ConsultantName"?: string;
  "isKycReviewed"?: boolean;
  "IsLoe"?: boolean;
  "LoeStatus"?: string;
  "QueryPaymentStatus"?: boolean;
  "LoeFileName"?: string;
  "DigiLoeDocId"?: string;
  "DigiLoeDetails"?: any;
  "LoeExpirationDate"?: Date;
  SubCategory?: FnqSubCategory;
  Category?: FnqCategory;
  Consultant?: FnqConsultant;
  Customer?: FnqCustomer;
}

export class FnqQuery implements FnqQueryInterface {
  "Id": string;
  "Title": string;
  "CategoryId": string;
  "SubcategoryId": string;
  "QueryDesc": string;
  "QueryType": string;
  "QueryChargeType": string;
  "CustomerId": string;
  "ConsultantId": string;
  "Answered": boolean;
  "Closed": boolean;
  "ClosedOn": Date;
  "Iterations": number;
  "CreatedOn": Date;
  "CreatedById": string;
  "CreatedByName": string;
  "ModifiedOn": Date;
  "ModifiedBy": string;
  "ExpectedResolution": string;
  "CustomerTurn": boolean;
  "ConsultantTurn": boolean;
  "IsActive": boolean;
  "Forwarded": boolean;
  "IsAmtRequested": boolean;
  "Rating": number;
  "PreferedQualification": string;
  "MeetingUrl": string;
  "MeetingType": string;
  "MeetingDuration": number;
  "MeetingTime": string;
  "IsMeeting": boolean;
  "Hour24": boolean;
  "Hour48": boolean;
  "Meetings": Array<any>;
  "Docs": Array<any>;
  "Answers": Array<any>;
  "FinancialHealthCheck": any;
  "CustomerPaymentStatus": boolean;
  "CustomerPaymentDetails": any;
  "CustomerName": string;
  "ConsultantName": string;
  "isKycReviewed": boolean;
  "IsLoe": boolean;
  "LoeStatus": string;
  "QueryPaymentStatus": boolean;
  "LoeFileName": string;
  "DigiLoeDocId": string;
  "DigiLoeDetails": any;
  "LoeExpirationDate": Date;
  SubCategory: FnqSubCategory;
  Category: FnqCategory;
  Consultant: FnqConsultant;
  Customer: FnqCustomer;
  constructor(data?: FnqQueryInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `FnqQuery`.
   */
  public static getModelName() {
    return "FnqQuery";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of FnqQuery for dynamic purposes.
  **/
  public static factory(data: FnqQueryInterface): FnqQuery{
    return new FnqQuery(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'FnqQuery',
      plural: 'FnqQueries',
      path: 'FnqQueries',
      idName: 'Id',
      properties: {
        "Id": {
          name: 'Id',
          type: 'string'
        },
        "Title": {
          name: 'Title',
          type: 'string'
        },
        "CategoryId": {
          name: 'CategoryId',
          type: 'string'
        },
        "SubcategoryId": {
          name: 'SubcategoryId',
          type: 'string'
        },
        "QueryDesc": {
          name: 'QueryDesc',
          type: 'string'
        },
        "QueryType": {
          name: 'QueryType',
          type: 'string'
        },
        "QueryChargeType": {
          name: 'QueryChargeType',
          type: 'string'
        },
        "CustomerId": {
          name: 'CustomerId',
          type: 'string'
        },
        "ConsultantId": {
          name: 'ConsultantId',
          type: 'string'
        },
        "Answered": {
          name: 'Answered',
          type: 'boolean'
        },
        "Closed": {
          name: 'Closed',
          type: 'boolean'
        },
        "ClosedOn": {
          name: 'ClosedOn',
          type: 'Date'
        },
        "Iterations": {
          name: 'Iterations',
          type: 'number'
        },
        "CreatedOn": {
          name: 'CreatedOn',
          type: 'Date'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedByName": {
          name: 'CreatedByName',
          type: 'string'
        },
        "ModifiedOn": {
          name: 'ModifiedOn',
          type: 'Date'
        },
        "ModifiedBy": {
          name: 'ModifiedBy',
          type: 'string'
        },
        "ExpectedResolution": {
          name: 'ExpectedResolution',
          type: 'string'
        },
        "CustomerTurn": {
          name: 'CustomerTurn',
          type: 'boolean'
        },
        "ConsultantTurn": {
          name: 'ConsultantTurn',
          type: 'boolean'
        },
        "IsActive": {
          name: 'IsActive',
          type: 'boolean'
        },
        "Forwarded": {
          name: 'Forwarded',
          type: 'boolean'
        },
        "IsAmtRequested": {
          name: 'IsAmtRequested',
          type: 'boolean'
        },
        "Rating": {
          name: 'Rating',
          type: 'number'
        },
        "PreferedQualification": {
          name: 'PreferedQualification',
          type: 'string'
        },
        "MeetingUrl": {
          name: 'MeetingUrl',
          type: 'string'
        },
        "MeetingType": {
          name: 'MeetingType',
          type: 'string'
        },
        "MeetingDuration": {
          name: 'MeetingDuration',
          type: 'number'
        },
        "MeetingTime": {
          name: 'MeetingTime',
          type: 'string'
        },
        "IsMeeting": {
          name: 'IsMeeting',
          type: 'boolean'
        },
        "Hour24": {
          name: 'Hour24',
          type: 'boolean'
        },
        "Hour48": {
          name: 'Hour48',
          type: 'boolean',
          default: false
        },
        "Meetings": {
          name: 'Meetings',
          type: 'Array&lt;any&gt;'
        },
        "Docs": {
          name: 'Docs',
          type: 'Array&lt;any&gt;'
        },
        "Answers": {
          name: 'Answers',
          type: 'Array&lt;any&gt;'
        },
        "FinancialHealthCheck": {
          name: 'FinancialHealthCheck',
          type: 'any'
        },
        "CustomerPaymentStatus": {
          name: 'CustomerPaymentStatus',
          type: 'boolean'
        },
        "CustomerPaymentDetails": {
          name: 'CustomerPaymentDetails',
          type: 'any'
        },
        "CustomerName": {
          name: 'CustomerName',
          type: 'string'
        },
        "ConsultantName": {
          name: 'ConsultantName',
          type: 'string'
        },
        "isKycReviewed": {
          name: 'isKycReviewed',
          type: 'boolean'
        },
        "IsLoe": {
          name: 'IsLoe',
          type: 'boolean'
        },
        "LoeStatus": {
          name: 'LoeStatus',
          type: 'string'
        },
        "QueryPaymentStatus": {
          name: 'QueryPaymentStatus',
          type: 'boolean'
        },
        "LoeFileName": {
          name: 'LoeFileName',
          type: 'string'
        },
        "DigiLoeDocId": {
          name: 'DigiLoeDocId',
          type: 'string'
        },
        "DigiLoeDetails": {
          name: 'DigiLoeDetails',
          type: 'any'
        },
        "LoeExpirationDate": {
          name: 'LoeExpirationDate',
          type: 'Date'
        },
      },
      relations: {
        SubCategory: {
          name: 'SubCategory',
          type: 'FnqSubCategory',
          model: 'FnqSubCategory',
          relationType: 'belongsTo',
                  keyFrom: 'SubcategoryId',
          keyTo: 'Id'
        },
        Category: {
          name: 'Category',
          type: 'FnqCategory',
          model: 'FnqCategory',
          relationType: 'belongsTo',
                  keyFrom: 'CategoryId',
          keyTo: 'Id'
        },
        Consultant: {
          name: 'Consultant',
          type: 'FnqConsultant',
          model: 'FnqConsultant',
          relationType: 'belongsTo',
                  keyFrom: 'ConsultantId',
          keyTo: 'Id'
        },
        Customer: {
          name: 'Customer',
          type: 'FnqCustomer',
          model: 'FnqCustomer',
          relationType: 'belongsTo',
                  keyFrom: 'CustomerId',
          keyTo: 'Id'
        },
      }
    }
  }
}
