import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { Country } from 'country-state-city';

import { MasterDataApi, MasterDataInterface } from 'src/app/shared/sdk';

import { Dropdown } from '../dtos.service';

@Injectable({
  providedIn: 'root'
})
export class MasterDataService {

  private masterData: any = {};
  public masterDataFetched = new BehaviorSubject(false);

  constructor(
    private masterDataApi: MasterDataApi,
    private httpClient: HttpClient
  ) { }

  async request(key) {
    try {
      var data = await this.masterDataApi.findOne<MasterDataInterface>({ where: { Key: key } }).toPromise().then(data => { return data; });
      return data;
    } catch (error) {
      console.log("Getting ", key, "==>", JSON.stringify(error));
      return null;
    }
  }

  async getGenders() {
    return await this.request("Genders");
  }

  async getLoanTypes() {
    return await this.request("Loan");
  }

  async getAssetTypes() {
    return await this.request("Assets");
  }

  async getOccupations() {
    return await this.request("Occupation");
  }

  async getResidentialStatus() {
    return await this.request("ResidentialStatus");
  }

  async getAccountTypes() {
    return await this.request("AccountType");
  }

  async getQueryTypes() {
    return await this.request("QueryType");
  }

  async getMeetingType() {
    return await this.request("MeetingType");
  }

  async getCategoryTypes() {
    return await this.request("CategoryType");
  }

  async getChargePercentage() {
    return await this.request("ChargePercentage");
  }

  async getEmployeeStatus() {
    return await this.request("EmployeeStatus");
  }

  async getFreeQueryDate() {
    return await this.request("FreeQueryDate");
  }

  async getRiskAssesment() {
    return await this.request("RiskAssessment");
  }

  async getDegrees() {
    return await this.request("Degrees");
  }

  async getRzpToken() {
    try {
      var data = await this.masterDataApi.getRzpToken().toPromise().then(data => { return data; });
      return {success: true, data: data, message: "Token fetched successfully"};
    } catch (error) {
      return {success: false, data: error, message: "Error while fetching token"};
    }
  }

  async getCountryList() {
    var countries = Country.getAllCountries();
    var countryDropdown: Dropdown[] = [];
    countries.forEach(element => {
      countryDropdown.push({
        value: element.isoCode,
        text: element.name,
      });
    });
    return countryDropdown;
  }

  fetchAllMasterData() {
    this.masterDataApi.find<MasterDataInterface>({}).subscribe(data => {
      data.forEach(element => {
        this.masterData[element.Key] = element.Value;
      });
      console.log("Master Data ===============>", this.masterData);
      this.masterDataFetched.next(true);
    });
  }



  public get $masterData(): any {
    return this.masterData;
  }

  public set $masterData(value: any) {
    this.masterData = value;
  }
}
