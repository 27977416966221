/* tslint:disable */
import {
  FnqCategory
} from '../index';

declare var Object: any;
export interface FnqSubCategoryInterface {
  "Id"?: string;
  "Name": string;
  "Active": boolean;
  "CategoryId": string;
  Category?: FnqCategory;
}

export class FnqSubCategory implements FnqSubCategoryInterface {
  "Id": string;
  "Name": string;
  "Active": boolean;
  "CategoryId": string;
  Category: FnqCategory;
  constructor(data?: FnqSubCategoryInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `FnqSubCategory`.
   */
  public static getModelName() {
    return "FnqSubCategory";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of FnqSubCategory for dynamic purposes.
  **/
  public static factory(data: FnqSubCategoryInterface): FnqSubCategory{
    return new FnqSubCategory(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'FnqSubCategory',
      plural: 'FnqSubCategories',
      path: 'FnqSubCategories',
      idName: 'Id',
      properties: {
        "Id": {
          name: 'Id',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "Active": {
          name: 'Active',
          type: 'boolean',
          default: true
        },
        "CategoryId": {
          name: 'CategoryId',
          type: 'string'
        },
      },
      relations: {
        Category: {
          name: 'Category',
          type: 'FnqCategory',
          model: 'FnqCategory',
          relationType: 'belongsTo',
                  keyFrom: 'CategoryId',
          keyTo: 'Id'
        },
      }
    }
  }
}
