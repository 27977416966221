import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { Dropdown } from './dtos.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private countries: Dropdown[] = [];
  gotCountries = new BehaviorSubject(false);
  private platform: string = "";
  public loeDocument = "loe"
  screenHeader = new BehaviorSubject("");


  public get $countries(): Dropdown[] {
    return this.countries;
  }

  public set $countries(value: Dropdown[]) {
    this.countries = value;
  }

  public get $platform(): string {
    return this.platform;
  }

  public set $platform(value: string) {
    this.platform = value;
  }


  constructor() { }

}
