import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { FnqConsultantPaymentInterface, FnqQueryPaymentApi, FnqQueryPaymentInterface } from 'src/app/shared/sdk';
import { FnqConsultantPaymentApi } from './../../shared/sdk/services/custom/FnqConsultantPayment';

import { NotifyUsersService } from '../notify-users.service';
import { MasterDataService } from '../masterData/master-data.service';
import { Router } from '@angular/router';

declare var RazorpayCheckout: any;
@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  public gotPayments = new BehaviorSubject(false);
  public paymentSuccess = new BehaviorSubject(false);
  public paymentUpdateSuccess = new BehaviorSubject(false);
  public paymentId = null;

  constructor(
    private consultantPaymentsApi:FnqConsultantPaymentApi,
    private queryPaymentsApi:FnqQueryPaymentApi,

    private notifyUserService: NotifyUsersService,
    private masterDataService: MasterDataService,
    private router: Router
  ) { }

  async handlePayment(amount: number, userProfile, orderId: string) {
    console.log("handlePayment ==>", orderId)
    this.paymentId = null
    var rzpToken = (await this.masterDataService.getRzpToken()).data.RzpToken;
    const options = {
      key: rzpToken,
      amount: (amount * 100).toString(),
      description: 'Credits towards consultation',
      image: 'http://finaqa.com/img/finaqa-red.png',
      currency: 'INR',
      order_id: orderId,
      name: userProfile.DisplayName,
      prefill: {
        email: userProfile.email,
        contact: userProfile.Phone,
        name: userProfile.DisplayName,
      },
      theme: {
        color: '#eb0023'
      },
    }
    var successCallback = (response) => {
      console.log("Razorpay success response ==================================>", response);
      if (response) {
        this.paymentId = response;
        this.paymentSuccess.next(true);
        
      }
    }
    var failureCallback = (response) => {
      console.log("Razorpay failure response ===================================>", response);
      if (response) {
        this.paymentSuccess.next(false);
        this.router.navigateByUrl('/sidebar/tabs/tabs/answer')
      }
    }
    RazorpayCheckout.open(options, successCallback, failureCallback);
  }

   async getAllConsultantPayments(){
     var data=await this.consultantPaymentsApi.find({include:[{relation:'Consultant',scope:{
       include:{relation:'User'}
     }}],order:'CreatedOn DESC'}).toPromise().then(data=>{return data})
     return {success:true,data:data}

   } 
   async payConsultant(Id,data){
      try {
        var paid=await this.consultantPaymentsApi.replaceById<FnqConsultantPaymentInterface>(Id,data).toPromise().then(data=>{return data})
        this.notifyUserService.notifyUser(null, data.ConsultantId, null, "PAYMENT_TO_CONSULTANT", null, null, null, null, null);
        return {success:true, paid:paid, message: 'Payment made' };
      } catch (error) {
        return { success:false, paid:null, message: error.message };
      }
   }
 
   

  async getClientPayments(clientId: string) {
    try {
      var queryPayments = await this.queryPaymentsApi.find<FnqQueryPaymentInterface>({ where: { CustomerId: clientId }, include: ['Query', 'Customer', 'Consultant'], order: 'CreatedOn DESC' }).toPromise().then(data => { return data });
      return { success: true, payments: queryPayments, message: 'Payments Recieved' };
    } catch (error) {
      console.log(error);
      return { success: true, payments: queryPayments, message: error.message };
    }

  }



  async getAllPayments(filter?,limit?,skip?){
    var count=await this.queryPaymentsApi.count(filter).toPromise().then(data=>{return data})
    var paymentstatus = await this.queryPaymentsApi.find({include:[{relation:'Query',scope:{
      include:'Consultant'
    }},{relation:'Customer'}],order:'CreatedOn DESC',limit:limit,skip:skip,where:filter}).toPromise().then(data=>{return data})
    return {success:true,payments:paymentstatus,count:count}
  }


async adminEditPaymentForward(Id,Data){
  var payment=await this.queryPaymentsApi.patchAttributes(Id,Data).toPromise().then(data=>{return data})
  return payment
}

  async editPaidPayments(Id){
    var queries=await this.queryPaymentsApi.upsertWithWhere({ConsultantPaymentHeaderId:Id},{ConsultantPaymentStatus:true}).toPromise().then(data=>{return data})
    return queries
  }
  async getPendingPayments(filter){
    try{
      console.log(filter)
    var queries=await this.queryPaymentsApi.find({where:filter}).toPromise().then(data=>{return data})
    return {success:true,payments:queries}
    }catch(err){
      return {success:false,payments:err}
    }


  }
  async makePayments() {

  }
}
