/* tslint:disable */
import {
  FnqQuery,
  FnqConsultant
} from '../index';

declare var Object: any;
export interface FnqRejectionsInterface {
  "Id"?: string;
  "RejectionReason"?: string;
  "RejectionType"?: string;
  "RejectedBy"?: string;
  "QueryId"?: string;
  "RejectedOn"?: Date;
  "ConsultantId"?: string;
  "CreatedOn"?: Date;
  "CreatedById": string;
  "CreatedByName"?: string;
  "ModifiedOn"?: Date;
  "ModifiedBy": string;
  Query?: FnqQuery;
  Consultant?: FnqConsultant;
}

export class FnqRejections implements FnqRejectionsInterface {
  "Id": string;
  "RejectionReason": string;
  "RejectionType": string;
  "RejectedBy": string;
  "QueryId": string;
  "RejectedOn": Date;
  "ConsultantId": string;
  "CreatedOn": Date;
  "CreatedById": string;
  "CreatedByName": string;
  "ModifiedOn": Date;
  "ModifiedBy": string;
  Query: FnqQuery;
  Consultant: FnqConsultant;
  constructor(data?: FnqRejectionsInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `FnqRejections`.
   */
  public static getModelName() {
    return "FnqRejections";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of FnqRejections for dynamic purposes.
  **/
  public static factory(data: FnqRejectionsInterface): FnqRejections{
    return new FnqRejections(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'FnqRejections',
      plural: 'FnqRejections',
      path: 'FnqRejections',
      idName: 'Id',
      properties: {
        "Id": {
          name: 'Id',
          type: 'string'
        },
        "RejectionReason": {
          name: 'RejectionReason',
          type: 'string'
        },
        "RejectionType": {
          name: 'RejectionType',
          type: 'string'
        },
        "RejectedBy": {
          name: 'RejectedBy',
          type: 'string'
        },
        "QueryId": {
          name: 'QueryId',
          type: 'string'
        },
        "RejectedOn": {
          name: 'RejectedOn',
          type: 'Date'
        },
        "ConsultantId": {
          name: 'ConsultantId',
          type: 'string'
        },
        "CreatedOn": {
          name: 'CreatedOn',
          type: 'Date'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedByName": {
          name: 'CreatedByName',
          type: 'string'
        },
        "ModifiedOn": {
          name: 'ModifiedOn',
          type: 'Date'
        },
        "ModifiedBy": {
          name: 'ModifiedBy',
          type: 'string'
        },
      },
      relations: {
        Query: {
          name: 'Query',
          type: 'FnqQuery',
          model: 'FnqQuery',
          relationType: 'belongsTo',
                  keyFrom: 'QueryId',
          keyTo: 'Id'
        },
        Consultant: {
          name: 'Consultant',
          type: 'FnqConsultant',
          model: 'FnqConsultant',
          relationType: 'belongsTo',
                  keyFrom: 'ConsultantId',
          keyTo: 'Id'
        },
      }
    }
  }
}
