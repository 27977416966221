import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { FileUploader } from 'ng2-file-upload';

import { ContainerApi, FnqConsultantApi, FnqConsultantInterface, FnqConsultantPaymentApi, FnqConsultantPaymentInterface, FnqCustomerApi, FnqCustomerInterface, FnqQueryApi, FnqQueryChargesApi, FnqQueryChargesInterface, FnqQueryInterface, FnqQueryPaymentApi, FnqQueryPaymentInterface, FnqRejectionsApi, FnqRejectionsInterface, LoopBackConfig, LoopBackFilter } from 'src/app/shared/sdk';

import { MasterDataService } from '../masterData/master-data.service';
import { NotifyUsersService } from '../notify-users.service';
import { UserService } from '../user/user.service';
import { Healthcheck } from '../dtos.service';

@Injectable({
  providedIn: 'root'
})
export class QueryService {

  private healthcheck: Healthcheck = {
    assets: {
      cash: 0,
      equity: 0,
      debt: 0,
      realEstate: 0,
      preciousMetals: 0,
    },
    loan: []
  };
  private queryData: FnqQueryInterface = null;
  uploader: FileUploader = null;
  queryId: string = null;
  isKYCDone = false;
  isRiskScoreDone = false;
  isProfileDone = false
  isConsultantSEBIRegistered = false
  kycErrorMessage = ""
  constructor(
    private consPaymentApi: FnqConsultantPaymentApi,
    private queryPaymentApi: FnqQueryPaymentApi,
    private queryChargeApi: FnqQueryChargesApi,
    private queryRejectApi: FnqRejectionsApi,
    private consultantApi: FnqConsultantApi,
    private customerApi: FnqCustomerApi,
    private containerApi: ContainerApi,
    private queryApi: FnqQueryApi,
    private http: HttpClient,

    private notifyUserService: NotifyUsersService,
    private masterDataService: MasterDataService,
    private user: UserService,
  ) { }

  clearQueryVariables() {
    this.queryId = null;
    this.uploader = null;
    this.queryData = null;
    this.kycErrorMessage = ""
  }

  async getAllQueries(filters, limit?, skip?, order?) {
    try {
      if (!order) {
        order = 'ModifiedOn DESC'
      }
      // filters['CustomerPaymentStatus'] = true
      var count = await this.queryApi.count(filters).toPromise().then(data => { return data })
      var queries = await this.queryApi.find({
        include: [{
          relation: 'Customer', scope: {
            include: {
              relation: 'User'
            }
          }
        }, { relation: 'Category' }, { relation: 'SubCategory' }, { relation: 'Consultant' }], order: order, where: filters
        , limit: limit, skip: skip
      }
      ).toPromise().then(data => { return data })


      return { success: true, queries: queries, count: count, message: "Queries Retrieved" }
    } catch (error) {
      console.log(error);
      return { success: false, queries: null, message: error.message }
    }
  }
  async getQueries(filter: LoopBackFilter = { where: {}, include: ['Category', 'SubCategory', 'Customer', 'Consultant'], order: 'CreatedOn DESC' }) {
    try {
      var user = this.user.getUserProfile();
      // filter.where['CustomerPaymentStatus'] = true;
      if (this.user.isCustomer()) {
        filter.where['CustomerId'] = user.Id;
      } else if (this.user.isConsultant()) {
        filter.where['ConsultantId'] = user.Id;
        filter.where["or"]  = 
          [{"CustomerPaymentStatus": true},{"and": [{"IsLoe": true}, {"CustomerPaymentStatus": false}]}]
        console.log("consultant filter===>", filter)
      }
      var queries: FnqQueryInterface[] = await this.queryApi.find<FnqQueryInterface>(filter).toPromise().then(data => { return data })
      return { success: true, queries: queries, message: "Queries Retrieved" }
    } catch (error) {
      console.log(error);
      return { success: false, queries: [], message: error.message }
    }
  }
  async getQuery(Id) {
    var query = await this.queryApi.findById(Id).toPromise().then(data => { return data })
    return { success: true, query: query, message: "Queries Retrieved" }
  }

  async getNumOfQueries(UserId) {
    try {

      var queries: { count: number; };
      if (UserId.startsWith('CS')) {
        queries = await this.queryApi.count({ CustomerId: UserId }).toPromise().then(data => { return data })
      } else if (UserId.startsWith('CN')) {
        queries = await this.queryApi.count({ ConsultantId: UserId }).toPromise().then(data => { return data })
      }
      console.log(queries)
      return { success: true, queries: queries, message: "Queries Retrieved" }
    } catch (error) {
      console.log(error);
      return { success: false, queries: null, message: error.message }
    }
  }

  async getAllFilesOfContainer(UserId: any) {

  }

  async getFile(containerName: any, fileId: any) {
    try {
      var file = await this.containerApi.getFile(containerName, fileId).toPromise().then(data => { return data });
      return { success: true, file: file, message: "Queries Retrieved" }
    } catch (error) {
      console.log(error);
      return { success: false, file: null, message: error.message }
    }
  }

  async downloadFile(containerName: any, fileId: any) {
    try {
      var file = await this.containerApi.download(containerName, fileId).subscribe(data => { console.log(data); });
      return { success: true, file: file, message: "Queries Retrieved" }
    } catch (error) {
      console.log(error);
      return { success: false, file: null, message: error.message }
    }
  }
  async getAllQueryCharges() {
    var charges = await this.queryChargeApi.find().toPromise().then(data => { return data })
    return { success: true, queries: charges }
  }

  async editQueryCharges(Id, data) {
    var edited = await this.queryChargeApi.replaceById(Id, data).toPromise().then(data => { return data })
    return { success: true, edited: edited }
  }


  async getQueryCharge(queryType: string) {
    try {
      var queryCharge: FnqQueryChargesInterface[];
      if (this.user.getUserProfile().OperatedAs == "INDIVIDUAL") {
        console.log({ where: { ChargesType: 'INDIVIDUAL', QueryType: queryType } });
        queryCharge = await this.queryChargeApi.find<FnqQueryChargesInterface>({ where: { and: [{ ChargesType: 'INDIVIDUAL' }, { QueryType: queryType }] } }).toPromise().then(data => { return data })
      } else if (this.user.getUserProfile().OperatedAs == "INDIVIDUAL_NRI") {
        console.log({ where: { ChargesType: 'PREMIUM', QueryType: queryType } });
        queryCharge = await this.queryChargeApi.find<FnqQueryChargesInterface>({ where: { ChargesType: 'PREMIUM', QueryType: queryType } }).toPromise().then(data => { return data })
      } else {
        console.log({ where: { ChargesType: 'BUSINESS', QueryType: queryType } });
        queryCharge = await this.queryChargeApi.find<FnqQueryChargesInterface>({ where: { ChargesType: 'BUSINESS', QueryType: queryType } }).toPromise().then(data => { return data })
      }
      return { success: true, queryCharge: queryCharge, message: "Queries Retrieved" }
    } catch (error) {
      console.log(error);
      return { success: false, queryCharge: null, message: error.message }
    }
  }

  async saveQuery(queryData: FnqQueryInterface, amount: number) {
    try {
      queryData.CustomerPaymentStatus = false;
      queryData.CustomerPaymentDetails = {};
      queryData.CustomerPaymentDetails['Amount'] = amount
      var query = await this.queryApi.create<FnqQueryInterface>(queryData).toPromise().then(data => { return data });
      return { success: true, query: query, message: "Query Submitted" }
    } catch (error) {
      console.log(error);
      return { success: false, query: null, message: error.message }
    }
  }

  async updateQuery(queryId: string, queryData: FnqQueryInterface, amount: number) {
    try {
      queryData.CustomerPaymentStatus = false;
      queryData.CustomerPaymentDetails = {};
      queryData.CustomerPaymentDetails['Amount'] = amount
      var query = await this.queryApi.upsertWithWhere<FnqQueryInterface>({ Id: queryId }, queryData).toPromise().then(data => { return data });
      return { success: true, query: query, message: "Query Submitted" }
    } catch (error) {
      console.log(error);
      return { success: false, query: null, message: error.message }
    }
  }

  async recordQueryPayment(queryData: FnqQueryInterface, paymentId: string, amountPaid: number) {
    try {
      queryData.CustomerPaymentStatus = true;
      queryData.CustomerPaymentDetails['RzpPaymentId'] = paymentId
      queryData.QueryPaymentStatus = true;
      console.log(queryData);
      var queryPayment = await this.queryPaymentApi.upsertWithWhere<FnqQueryPaymentInterface>({ QueryId: queryData.Id }, 
        { QueryId: queryData.Id, CustomerAmount: amountPaid, CustomerId: queryData.CustomerId, ConsultantId: queryData.ConsultantId, 
          CustomerPaymentstatus: true, PaidOn: new Date(), QueryType: queryData.QueryType, ChargesType: queryData.QueryChargeType, 
          QueryTitle: queryData.Title }).toPromise().then(data => { return data });
      console.log("queryPayment ==>", queryPayment)
      queryData.CustomerPaymentDetails["fnqPaymentId"] = queryPayment.Id
      var query = await this.queryApi.upsertWithWhere<FnqQueryInterface>({ Id: queryData.Id }, { CustomerPaymentStatus: true, CustomerPaymentDetails: queryData.CustomerPaymentDetails }).toPromise().then(data => { return data });
      console.log(query);
      
      var customer = this.customerApi.findById<FnqCustomerInterface>(queryData.CustomerId).toPromise().then(data => { return data; });
      customer = this.customerApi.upsertWithWhere<FnqCustomerInterface>({ Id: queryData.CustomerId }, { NumOfQueries: (await customer).NumOfQueries + 1, AmountSpent: (await customer).AmountSpent + amountPaid }).toPromise().then(data => { return data; });
      console.log("Query Payment ==> ", queryPayment);
      return { success: true, query: query, message: "Query Submitted" }
    } catch (error) {
      console.log(error);
      return { success: false, query: null, message: error.message }
    }
  }

  async forwardQuery(query: any) {
    try {
      var fwdQuery = await this.queryApi.replaceOrCreate<FnqQueryInterface>(query).toPromise().then(data => { return data; });
      console.log(fwdQuery);
      this.notifyUserService.notifyUser(fwdQuery.Id, null, null, "QUERY_ASSIGNED", null, null, null, null, null);
      return { success: true, query: fwdQuery, message: "Forwarded Successfully" }
    } catch (error) {
      console.log(error);
      return { success: false, query: null, message: error.message }
    }
  }

  async submitFeedback(queryId: number, rating: number) {
    try {
      var query = (await this.getQueries({ where: { Id: queryId } })).queries[0]
      query.Rating = rating;
      var feedbackSubmitted = await this.queryApi.replaceOrCreate<FnqQueryInterface>(query).toPromise().then(data => { return data; });
      var cons = await this.user.getUserProfileById(query.ConsultantId);
      var newRating = (cons.StarRating * cons.RatingCount + rating) / (cons.RatingCount + 1);
      var consultant = await this.consultantApi.upsertWithWhere<FnqConsultantInterface>({ Id: query.ConsultantId }, { RatingCount: cons.RatingCount + 1, StarRating: newRating }).toPromise().then(data => { return data; });
      return { success: true, submitFeedback: feedbackSubmitted, message: 'Feedback Submitted Successfully' };
    } catch (error) {
      console.log(error);
      return { success: false, submitFeedback: null, message: error.message };
    }
  }

  async closeQuery(query?: FnqQueryInterface) {
    try {
      //this.notifyUserService.notifyUser(query.Id, null, null, "QUERY_CLOSED", null, null, null, null, null);
      query.Closed = true;
      query.ClosedOn = new Date();
      query.ConsultantTurn = false;
      query.CustomerTurn = false;
      if (query.ConsultantId != undefined || query.ConsultantId != null) {
        var queryCharge = await this.queryChargeApi.findOne<FnqQueryChargesInterface>({ where: { and: [{ "ChargesType": query.QueryChargeType }, { "QueryType": query.QueryType }] } }).toPromise().then(data => { return data; });
        var queryAmount = await this.queryPaymentApi.findOne<FnqQueryPaymentInterface>({ where: { QueryId: query.Id } }).toPromise().then(data => { return data; });
        var cons = await this.consultantApi.findById<FnqConsultantInterface>(query.ConsultantId).toPromise().then(data => { return data; });
        var my_date = new Date();
        var amount = queryAmount.CustomerAmount;
        var first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 1);
        var last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0);
        var ConsPer = queryCharge.ConsultantPercent / 100
        var consGST = cons.GstNumber ? amount * ConsPer * this.masterDataService.$masterData.ChargePercentage.GST_PERCENT / 100 : 0;
        var consTDS = amount * ConsPer * this.masterDataService.$masterData.ChargePercentage.TDS_PERCENT / 100;
        var pgCharge = amount * this.masterDataService.$masterData.ChargePercentage.PG_PERCENT / 100;
        var consPayout: number = amount * ConsPer + consGST - consTDS;
        var consPayment = await this.consPaymentApi.findOne<FnqConsultantPaymentInterface>({ where: { and: [{ ConsultantId: query.ConsultantId }, { FromDate: first_date }, { ToDate: last_date }] } }).toPromise().then(data => { return { success: true, consultantHeader: data, message: "Consultant header Found" }; }).catch(error => { return { success: false, consultantHeader: error, message: "No Consultant header exists" }; });
        if (!consPayment.success) {
          consPayment = await this.consPaymentApi.create<FnqConsultantPaymentInterface>({ ConsultantId: query.ConsultantId, FromDate: first_date, ToDate: last_date, PaymentStatus: false, BankReferenceId: null, Amount: consPayout }).toPromise().then(data => { return { success: true, consultantHeader: data, message: "Consultant header Created" }; });
        } else {
          consPayment = await this.consPaymentApi.upsertWithWhere<FnqConsultantPaymentInterface>({ and: [{ ConsultantId: query.ConsultantId }, { FromDate: first_date }, { ToDate: last_date }] }, { Amount: consPayment.consultantHeader.Amount + consPayout }).toPromise().then(data => { return { success: true, consultantHeader: data, message: "Consultant header Updated" }; });
        }
        console.log("Consultant Payment ==> ", consPayment);
        var queryPayment = await this.queryPaymentApi.upsertWithWhere<FnqQueryPaymentInterface>({ QueryId: query.Id }, {
          ConsultantId: query.ConsultantId, ConsultantPaymentHeaderId: consPayment.consultantHeader.Id, ConsultantPaymentStatus: false, ConsultantPayout: consPayout, ConsulantGST: consGST, ConsultantTDS: consTDS, PaymentGatewayCharge: pgCharge, ConsultantAmount: amount * ConsPer
        }).toPromise().then(data => { return data });
        console.log("Query Payment ==> ", queryPayment);
      }
      var closedQuery = await this.queryApi.replaceOrCreate<FnqQueryInterface>(query).toPromise().then(data => { return data; });
      console.log(closedQuery);
      this.notifyUserService.notifyUser(query.Id, null, null, "QUERY_CLOSED", null, null, null, null, null);
      return { success: true, closedQuery: closedQuery, message: "Closed Successfully" }
    } catch (error) {
      console.log("Error in replying ==>", error);
      return { success: false, closedQuery: null, message: error.message }
    }
  }

  async skipQuery(query: FnqQueryInterface, skipReason: string) {
    try {
      
      var query: FnqQueryInterface = await (await this.getQueries({ where: { Id: query.Id } })).queries[0];
      if(query.CustomerPaymentStatus){
        var qryPayment = await this.queryPaymentApi.upsertWithWhere<FnqQueryPaymentInterface>({ QueryId: query.Id }, { ConsultantId: null }).toPromise().then(data => { return data; });
      }
      var queryRejected = await this.queryRejectApi.create<FnqRejectionsInterface>({
         QueryId: query.Id, 
         RejectionReason: skipReason, 
         ConsultantId: query.ConsultantId, 
         RejectionType: "QUERY_SKIP", 
         RejectedOn: new Date(), 
         CreatedById: this.user.getUserProfile().Id,
         ModifiedBy:  this.user.getUserProfile().DisplayName}).toPromise().then(data => { return data; });
         
      query.ConsultantId = null;
      query.Forwarded = false;
      query.ConsultantName = null;
      var skipped = await this.queryApi.replaceOrCreate<FnqQueryInterface>(query).toPromise().then(data => { return data; });
      this.notifyUserService.notifyUser(query.Id, null, null, 'QUERY_SKIPPED', null, null, null, null, null);
      
      return { success: true, skippedQuery: skipped, message: "Replied Successfully" }
    } catch (error) {
      console.log("Error in replying ==>", error);
      return { success: false, skipperQuery: null, message: error.message }
    }
  }

  updateQueryDetails(queryId, data){
    this.queryApi.patchAttributes(queryId, data).subscribe(
      data => {console.log("query updated successfully")},
      data => {console.log("query updated successfully")}
    );
  }

  async fileUpload(containerName: string, formData: FormData) {
    try {
      var headers: HttpHeaders = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data')
      var options = { headers: headers }
      var url = ['http:', LoopBackConfig.getPath(), LoopBackConfig.getApiVersion(), 'Containers', containerName, 'upload'].join('/')
      var postedData = await this.http.post(url, formData, options).toPromise().then(data => { return data })
      return { success: true, file: postedData, message: "File Uploaded" }
    } catch (error) {
      console.log(error);
      return { success: false, file: null, message: error.message }
    }
  }

  async sendReply(queryWithReply: FnqQueryInterface) {
    try {
      var replied = await this.queryApi.replaceOrCreate<FnqQueryInterface>(queryWithReply).toPromise().then(data => { return data; });
      console.log(replied);
      return { success: true, replied: replied, message: "Replied Successfully" }
    } catch (error) {
      console.log("Error in replying ==>", error);
      return { success: false, replied: null, message: error.message }
    }
  }

  async updateDisplayName(id, name) {
    try {
      var queries;
      if (id.startsWith('CN')) {
        queries = await this.queryApi.updateAll({ ConsultantId: id }, { ConsultantName: name }).toPromise().then(data => { return data });
        console.log(queries);
      } else {
        queries = await this.queryApi.updateAll({ CustomerId: id }, { CustomerName: name }).toPromise().then(data => { return data });
        console.log(queries);
      }
      return { success: true, queries: queries, message: "Updated Successfully" }
    } catch (error) {
      console.log(error);
      return { success: false, queries: null, message: error.message }
    }
  }

  getPrevConsultants(categoryId, subCategoryId, userId){
    var filter: LoopBackFilter = {}
    filter.where = {}
    filter.where["CategoryId"] = categoryId 
    filter.where["SubcategoryId"] = subCategoryId
    filter.where["CreatedById"] = userId
    filter.order = ["CreatedOn desc"]
    filter.limit=1
      
    return this.queryApi.find(filter)
  }

  public get $healthcheck(): Healthcheck {
    return this.healthcheck;
  }

  public set $healthcheck(value: Healthcheck) {
    this.healthcheck = value;
  }

  public get $queryData(): FnqQueryInterface {
    return this.queryData;
  }

  public set $queryData(value: FnqQueryInterface) {
    this.queryData = value;
  }

}
