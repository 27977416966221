import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { FnqConsultantApi, FnqConsultantInterface, FnqCustomerApi, FnqCustomerInterface, FnqUserApi, FnqUserInterface, LoopBackAuth } from '../shared/sdk';

import { FCMService } from './fcm.service';
import { UserService } from './user/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isAuthenticated = new BehaviorSubject(false);

  constructor(
    private auth: LoopBackAuth,

    private consultantApi: FnqConsultantApi,
    private customerApi: FnqCustomerApi,
    private userApi: FnqUserApi,

    private userService: UserService,
    private FcmService: FCMService,
  ) { }

  async login(creds: any) {
    try {
      var user = await this.userApi.login(creds).toPromise().then(data => { return data })
      // Do NOT Remove this Log !!!!
      console.log(user)
      if (user.user.RoleName == 'CUSTOMER') {
        var customer = await this.customerApi.findOne({ where: { UserId: user.user.Id } }).toPromise().then(data => { return data; });
        console.log("Customer ==> ", customer);
        this.userService.setUserProfile(Object.assign(user.user, customer));
      } else if (user.user.RoleName == 'CONSULTANT') {
        var consultant = await this.consultantApi.findOne({ where: { UserId: user.user.Id } }).toPromise().then(data => { return data; });
        console.log("Consultant ==> ", consultant);
        this.userService.setUserProfile(Object.assign(user.user, consultant));
      }
      this.isAuthenticated.next(true);
      console.log("User ==> ", this.auth.getCurrentUserData());
      console.log("is Authenticated ==> ", this.isAuthenticated.value);
      return { success: true, user: user.user, message: "Login Successful" }
    } catch (error) {
      console.log(error);
      if (error.statusCode == 401){
        if(error.code == "LOGIN_FAILED_EMAIL_NOT_VERIFIED"){
          return { success: false, user: null, message: error.message }  
        }else{
          return { success: false, user: null, message: "Invalid Email or Password" }
        }
      }
      return { success: false, user: error, message: error.message }
    }
  }

  async register(userDetails: FnqUserInterface, isCustomer: boolean, custDetails?: FnqCustomerInterface, consDetails?:FnqConsultantInterface ) {
    var retVal: { success: boolean, user: object, message: string } = null;
    try {
      var data
      if (userDetails.Id){
        data = await this.userApi.upsertWithWhere<FnqUserInterface>({ Id: userDetails.Id }, userDetails).toPromise().then(data => { return data });
      } else {
        data = await this.userApi.create<FnqUserInterface>(userDetails).toPromise().then(data => { return data });
      }
      var custConsData: FnqCustomerInterface | FnqConsultantInterface;

      if (isCustomer) {
        custDetails.UserId = data.Id;
        if (custDetails.Id) {
          custConsData = await this.customerApi.upsertWithWhere<FnqCustomerInterface>({ Id: custDetails.Id }, custDetails).toPromise().then(data => { return data });
        } else {
          custConsData = await this.customerApi.create<FnqCustomerInterface>(custDetails).toPromise().then(data => { return data });
        }
      } else {
        consDetails.UserId = data.Id;
        if (consDetails.Id) {
          custConsData = await this.consultantApi.upsertWithWhere<FnqConsultantInterface>({ Id: consDetails.Id }, consDetails).toPromise().then(data => { return data });
        } else {
          custConsData = await this.consultantApi.create<FnqConsultantInterface>(consDetails).toPromise().then(data => { return data });
        }
      }
      var user =  Object.assign(data, custConsData);
      retVal = { success: true, user: user, message: "Login Successful" }
    } catch (error) {
      console.log("Error Regiserting User ==> ", error);
      if(error.statusCode==422) {
        retVal = { success: false, user: null, message: "Email already in use." }
      } else {
        retVal = { success: false, user: null, message: error.message }
      }
    }
    return retVal
  }

  async logout() {
    var retVal: { success: boolean, user: object, message: string } = null;
    try {
      this.FcmService.clearInstallation(this.auth.getCurrentUserData().Id);
      console.log(this.auth.getToken());
      var user = await this.userApi.logout().toPromise().then(data => { return data })
      this.isAuthenticated.next(false);
      console.log("User ==>", user);
      retVal = { success: true, user: user, message: "Logout Successful" }
      console.log(this.auth.getToken())
    } catch (error) {
      console.log("Error Logging out ==> ", error);
      retVal = { success: false, user: null, message: error }
    }
    return retVal
  }

  async changePass(oldPasswword, newPassword) {
    var retVal;
    try {
      var changePass = await this.userApi.changePassword(oldPasswword, newPassword).toPromise().then(data => { return data; })
      retVal = { success: true, changePass: changePass, message: "Logout Successful" }
    } catch  (error) {
      console.log("Error Logging out ==> ", error);
      retVal = { success: false, changePass: null, message: error.message.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) }
    }
    return retVal
  }

  async forgotPassword(email) {
    var retVal: { success: boolean; forgotPass: any; message: any; };
    try {
      var forgotPass = await this.userApi.resetPassword({email: email}).toPromise().then(data => { return data; })
      retVal = { success: true, forgotPass: forgotPass, message: "Email Sent." }
    } catch  (error) {
      console.log("Error  out ==> ", error);
      retVal = { success: false, forgotPass: null, message: error.message}
    }
    return retVal
  }

  async setPassword(authToken, newPass) {
    var retVal: { success: boolean, setPass: any, message: any };
    try {
      var setPass = await this.userApi.setPassword(newPass, authToken).toPromise().then(data => { return data; })
      retVal = { success: true, setPass: setPass, message: "Password Changed." }
    } catch  (error) {
      console.log("Error  out ==> ", error);
      retVal = { success: false, setPass: null, message: error.message}
    }
    return retVal
  }

  async updatePasswordWithToken(authToken, newPass) {
    var retVal: { success: boolean, setPass: any, message: any };
    try {
      var setPass = await this.userApi.updatePasswordFromToken(authToken, newPass).toPromise().then(data => { return data; })
      // console.log("setPass ===>", setPass)
      retVal = { success: true, setPass: setPass, message: "Password Changed." }
    } catch  (error) {
      console.log("Error  out ==> ", error);
      retVal = { success: false, setPass: null, message: error.message}
    }
    return retVal
  }
}
