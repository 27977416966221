/* tslint:disable */
import {
  FnqUser
} from '../index';

declare var Object: any;
export interface FnqCustomerInterface {
  "Id"?: string;
  "RiskScore"?: number;
  "RiskScoreTimestamp"?: Date;
  "ResidentStatus": string;
  "AadharNumber"?: string;
  "PanNumber"?: string;
  "KycRequestId"?: string;
  "KycComplete"?: boolean;
  "KycStatus"?: string;
  "KycValidity"?: Date;
  "OperatedAs": string;
  "UserId"?: string;
  "DisplayName"?: string;
  "NumOfQueries"?: number;
  "AmountSpent"?: number;
  User?: FnqUser;
}

export class FnqCustomer implements FnqCustomerInterface {
  "Id": string;
  "RiskScore": number;
  "RiskScoreTimestamp": Date;
  "ResidentStatus": string;
  "AadharNumber": string;
  "PanNumber": string;
  "KycRequestId": string;
  "KycComplete": boolean;
  "KycStatus": string;
  "KycValidity": Date;
  "OperatedAs": string;
  "UserId": string;
  "DisplayName": string;
  "NumOfQueries": number;
  "AmountSpent": number;
  User: FnqUser;
  constructor(data?: FnqCustomerInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `FnqCustomer`.
   */
  public static getModelName() {
    return "FnqCustomer";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of FnqCustomer for dynamic purposes.
  **/
  public static factory(data: FnqCustomerInterface): FnqCustomer{
    return new FnqCustomer(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'FnqCustomer',
      plural: 'FnqCustomers',
      path: 'FnqCustomers',
      idName: 'Id',
      properties: {
        "Id": {
          name: 'Id',
          type: 'string'
        },
        "RiskScore": {
          name: 'RiskScore',
          type: 'number'
        },
        "RiskScoreTimestamp": {
          name: 'RiskScoreTimestamp',
          type: 'Date'
        },
        "ResidentStatus": {
          name: 'ResidentStatus',
          type: 'string'
        },
        "AadharNumber": {
          name: 'AadharNumber',
          type: 'string'
        },
        "PanNumber": {
          name: 'PanNumber',
          type: 'string'
        },
        "KycRequestId": {
          name: 'KycRequestId',
          type: 'string'
        },
        "KycComplete": {
          name: 'KycComplete',
          type: 'boolean',
          default: false
        },
        "KycStatus": {
          name: 'KycStatus',
          type: 'string',
          default: 'false'
        },
        "KycValidity": {
          name: 'KycValidity',
          type: 'Date'
        },
        "OperatedAs": {
          name: 'OperatedAs',
          type: 'string'
        },
        "UserId": {
          name: 'UserId',
          type: 'string'
        },
        "DisplayName": {
          name: 'DisplayName',
          type: 'string'
        },
        "NumOfQueries": {
          name: 'NumOfQueries',
          type: 'number'
        },
        "AmountSpent": {
          name: 'AmountSpent',
          type: 'number'
        },
      },
      relations: {
        User: {
          name: 'User',
          type: 'FnqUser',
          model: 'FnqUser',
          relationType: 'belongsTo',
                  keyFrom: 'UserId',
          keyTo: 'Id'
        },
      }
    }
  }
}
